import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import DatePicker from 'components/final-form/DatePicker';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import moment from 'moment';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Loader } from 'semantic-ui-react';
import { exportClinicStats, getClinicStats } from 'service/clinicStatsService';
import axios from 'service/http';
import { ClinicStatsRecord, ClinicStatsRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';

const cancelTokenSource = axios.CancelToken.source();

interface Props {
}

const ClinicStatsView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { clinicInfo, setClinicId } = useClinicInfoContext();

  const { state } = useLocation();
  const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;
  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;

  const [clinicStats, setClinicStats] = useState<ClinicStatsRecord[]>([]);
  const [statsLoaded, setStatsLoaded] = useState<boolean>(true);

  const [total, setTotal] = useState<number>(0);

  const [searchValues, setSearchValues] = useState<ClinicStatsRequest>({
    clinicId: clinicId!,
    fromDate: moment().startOf('year').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchStats(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.userNotAdmin];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const exportStats = () => {
    exportClinicStats(searchValues, cancelTokenSource)
    .catch((e: any) => handleError(e.response.data));
  };

  const handleSubmit = (values: ClinicStatsRequest) => {
    setSearchValues(values);
    fetchStats(values);
  };

  const calculateTotal = (stats?: ClinicStatsRecord[]) => {

    let totalCount = 0;

    const cStats = stats ? stats : clinicStats;
    cStats.forEach(clinicStat => {
      totalCount = totalCount + clinicStat.total;
    });

    setTotal(totalCount);
  };

  const fetchStats = (values: ClinicStatsRequest): void => {

    setClinicId(values.clinicId);

    const onFinally = () => {
      setStatsLoaded(true);
    };

    setStatsLoaded(false);
    setClinicStats([]);

    getClinicStats(values, cancelTokenSource)
    .then(response => {
        setClinicStats(response.clinicStats);
        calculateTotal(response.clinicStats);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);
  };

  const staffCellRenderer = ({ rowData }: any) => {
    let staff = '';

    if (rowData.lastName) {
      staff = rowData.lastName;
    }

    if (rowData.name) {
      staff += ' ' + rowData.name;
    }

    return staff;
  };

  const renderClinicStatsTable = (): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={clinicStats.length}
        rowGetter={clinicStatsRowGetter}
        rowRenderer={clinicStatsRowRenderer}
        columns={[
          {
            width: 300,
            label: t('doctor.viewTitle'),
            dataKey: 'lastName',
            flexGrow: 1,
            cellRenderer: staffCellRenderer,
          },
          {
            width: 200,
            label: t('clinic.openTests'),
            dataKey: 'openNumOfTests',
          },
          {
            width: 200,
            label: t('clinic.completeTests'),
            dataKey: 'completeNumOfTests',
          },
          {
            width: 200,
            label: t('clinic.totalTests'),
            dataKey: 'totalNumOfTests',
          },
          {
            width: 200,
            label: t('clinic.openExercises'),
            dataKey: 'openNumOfExercises',
          },
          {
            width: 200,
            label: t('clinic.completeExercises'),
            dataKey: 'completeNumOfExercises',
          },
          {
            width: 200,
            label: t('clinic.totalExercises'),
            dataKey: 'totalNumOfExercises',
          },
          {
            width: 100,
            label: t('clinic.total'),
            dataKey: 'total',
          },
        ]}
      />
    );
  };

  const renderClinicStatsForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={(values: ClinicStatsRequest) => handleSubmit(values)}
        initialValues={searchValues}
        subscription={{ pristine: true, values: true, submitting: true }}
        render={(formProps: FormRenderProps<ClinicStatsRequest>) => renderSearchFormContent(formProps)}
      />
    );
  };

  const renderSearchFormContent = ({
                                     handleSubmit,
                                     submitting,
                                     values,
                                   }: FormRenderProps<ClinicStatsRequest>): React.ReactNode => {

    const disabledBefore = values.fromDate && new Date(values.fromDate);

    const clinicName = clinicInfo ? `(${clinicInfo.name})` : "";

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('clinic.stats')} {clinicName}</div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <form onSubmit={handleSubmit}>
          <TsaGrid>
            <Grid.Row>
              <Grid.Column width={3}>
                <DataLabel>{t('clinicStats.from')}</DataLabel>
                <Field
                  name='fromDate'
                  component={DatePicker}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <DataLabel>{t('clinicStats.to')}</DataLabel>
                <Field
                  name='toDate'
                  component={DatePicker}
                  disabledBefore={disabledBefore}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={6} verticalAlign='bottom'>
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting}
                  onClick={noop}
                >
                  {t('button.search')}
                </CompositeButton>
                <span className='total-text'>{t('clinic.total')}: {total}</span>
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </form>

        <div className='data-table'>
          {statsLoaded && renderClinicStatsTable()}
          {!statsLoaded &&
            <Loader className='table-loader' active inline content={t('clinic.loading')} />}
        </div>

        <div
          style={{ width: '100%', height: '1px', backgroundColor: 'var(--very-light-blue)', marginTop: '1rem', marginBottom: '1rem' }}>
        </div>

        <div style={{ width: '100%', marginBottom: '1rem' }}>
          <CompositeButton
            type='button'
            className='action-button'
            secondary
            onClick={() => history.push(prevPath!, { id: clinicId, clinicId: state.clinicId })}
            floated='right'
          >
            {t('action.back')}
          </CompositeButton>
          <CompositeButton
            type='button'
            className='action-button'
            primary
            onClick={exportStats}
            floated='right'
          >
            {t('previewResult.export')}
          </CompositeButton>
        </div>
      </>
    );
  };

  const clinicStatsRowGetter = ({ index }: any) => {

    Object.assign(clinicStats[index], { index: index + 1 });

    return clinicStats[index];
  };

  const clinicStatsRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderClinicStatsForm()}
    </SearchDisplayContainer>
  );
};

export default ClinicStatsView;