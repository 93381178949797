import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import DatePicker from 'components/final-form/DatePicker';
import Input from 'components/final-form/Input';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Grid, GridColumn, GridRow, Modal } from 'semantic-ui-react';
import { ClinicInvoicePaymentRequestDto } from 'ts-types/api.types';
import styled from 'styled-components';

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);
      
    :hover {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);
    margin-right: 1rem;  
    
    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
  onDismissError: () => void;
  onSubmit: (values: ClinicInvoicePaymentRequestDto) => void;
  errorMessages: string[];
  initialValues: ClinicInvoicePaymentRequestDto;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ open, onClose, onSubmit, initialValues, errorMessages, onDismissError }) => {
  const { t } = useTranslation('teresa');

  return (
    <Modal
      style={{ position: 'sticky' }}
      open={open}
      closeOnDimmerClick={true}
      onClose={onClose}
      size={'tiny'}
    >
      <Modal.Header>{t('clinicInvoice.paymentInformation')}</Modal.Header>
      {
        errorMessages.length > 0 &&
        <Grid.Row>
          <Grid.Column width={16}>
            <div className='error'>
              <StyledErrorMessage onDismiss={onDismissError}>
                {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
              </StyledErrorMessage>
            </div>
          </Grid.Column>
        </Grid.Row>
      }
      <FinalForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TsaGrid>
              <GridRow>
                <GridColumn style={{ marginLeft: 15 }} width={8}>
                  <DataLabel>{t('clinicInvoice.paymentDate')}</DataLabel>
                  <Field
                    name='paymentDate'
                    component={DatePicker}
                    fluid
                  />
                </GridColumn>
                <GridColumn width={7}>
                  <DataLabel>{t('clinicInvoice.paymentAmount')}</DataLabel>
                  <Field
                    name='paymentAmount'
                    component={Input}
                    numberInput={true}
                    fluid
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16}>
                  <ActionContainer>
                    <CompositeButton
                      type='button'
                      className='action-button'
                      style={{ whiteSpace: 'nowrap' }}
                      secondary
                      floated='right'
                      onClick={onClose}
                    >
                      {t('button.close')}
                    </CompositeButton>
                    <CompositeButton
                      type='submit'
                      className='action-button'
                      style={{ whiteSpace: 'nowrap' }}
                      primary
                      floated='right'
                    >
                      {t('clinicInvoice.pay')}
                    </CompositeButton>
                  </ActionContainer>
                </GridColumn>
              </GridRow>
            </TsaGrid>
          </form>
        )}
      />
    </Modal>
  );
};

export default PaymentModal;