import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import ViewContentWrapperDiv from 'components/ViewContentWrapperDiv';
import VirtualizedTable from 'components/VirtualizedTable';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ExerciseTemplateModal from 'routes/patientexercising/ExerciseTemplateModal';
import TestTemplateModal from 'routes/patienttesting/TestTemplateModal';
import { Loader, Popup } from 'semantic-ui-react';
import { getAllQuestionSets } from 'service/teresaConfService';
import axios from 'service/http';
import { getPatientDetails } from 'service/patientService';
import { deletePatientTestingArchive, getRecentActivities } from 'service/patientTestingService';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { PatientTestingActionType, PatientTestingStatus } from 'ts-types/api.enums';
import { PatientTestingActivityResponse, QuestionSetDto } from 'ts-types/api.types';
import { isoToDisplayDateTime } from 'util/dateUtils';
import { noop } from 'util/functionUtils';
import { emptyTableCell, shortLabel } from 'util/tableUtils';

const StyledSpan = styled.span`
  display: flex;

  .patient-origin-id {
    margin-right: 0.5rem;
  }

  .patient-id {
    color: blue;
  }
`;

const PopupTriggerContainer = styled.div`
  display: inline-block;
`;

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const Dashboard = (props: Props) => {

  const { state } = useLocation();

  const testDuration: string | undefined = state?.testDuration ? String(state?.testDuration) : undefined;
  const isIpad = useIsIpadWidthOrBelow();

  const { t } = useTranslation('teresa');
  const { currentUser } = useAuthContext();
  const { push } = useHistory();
  const { language } = useAuthContext();

  const [foundPatientActivities, setFoundPatientActivities] = useState<PatientTestingActivityResponse[]>([]);
  const [patientActivitiesLoaded, setPatientActivitiesLoaded] = useState<boolean>(true);
  const [selectedPatientId, setSelectedPatientId] = useState<number | undefined>(undefined);
  const [questionSets, setQuestionSets] = useState<QuestionSetDto[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [exerciseModalOpen, setExerciseModalOpen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = debounce((): void => {

    const onFinally = () => {
      setPatientActivitiesLoaded(true);
    };

    setPatientActivitiesLoaded(false);

    if (currentUser) {

      const id = currentUser?.id;

      getAllQuestionSets(cancelTokenSource)
      .then(responses => setQuestionSets(responses))
      .catch((e: any) => handleError(e.response.data));

      getRecentActivities(id, cancelTokenSource)
      .then(responses => {
        setFoundPatientActivities(responses);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(onFinally);
    }
  }, 300);

  const onSysAdministratorClick = () => {
    push('/clinic/search');
  };

  const onClinicAdministratorClick = () => {
    push('/clinic/details');
  };

  const openTestTemplateModal = (patientId: number) => {
    setSelectedPatientId(patientId);
    setModalOpen(true);
  };

  const onOpenExerciseModal = (patientId: number) => {
    setSelectedPatientId(patientId);
    setExerciseModalOpen(true);
  };
  const onCloseExerciseModal = () => {
    setExerciseModalOpen(false);
  };

  const goToPatientDetails = (id: number, clinicId: number) => {
    push('/patient/details', { id: id, clinicId: clinicId, prevPath: '/' });
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const renderDataContent = (): React.ReactNode => {
    const lastLogin = moment(currentUser!.lastLogin).format('dddd, MMMM DD, YYYY - HH:mm');
    return (
      <div>
        <div className='page-header'>
          <div className='title-h1'>{t('dashboard.title')}</div>
          <div className='sub-title'>
            <span className='normal-text'>{t('dashboard.lastLogin')}</span> {lastLogin}
          </div>
        </div>
        <div className='table-title'>{t('dashboard.recentActivities')}</div>
      </div>
    );
  };

  const activitiesRowGetter = ({ index }: any) => {

    if (foundPatientActivities.length > 0) {
      Object.assign(foundPatientActivities[index], { index: index + 1 });

      return foundPatientActivities[index];
    }
  };

  const patientTestAgeRenderer = ({ rowData }: any) => {
    const patientTestAge = rowData.patientTestAge;

    return (
      <div>{patientTestAge ? `${patientTestAge}` : emptyTableCell()}</div>
    );
  };

  const activitiesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const renderRecentActivitiesTable = (): JSX.Element => {

    let columns: React.ReactNode[] = [
      {
        width: 120,
        flexShrink: 0,
        label: t('dashboard.dateTime'),
        dataKey: 'activityDateTime',
        cellRenderer: (dateTimeCellRenderer),
      },
      {
        width: 100,
        label: t('dashboard.age'),
        dataKey: 'patientTestedAge',
        cellRenderer: patientTestAgeRenderer,
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('dashboard.patientId'),
        dataKey: 'patientId',
        cellRenderer: (patientIdCellRenderer),
      },
    ];

    if (!isIpad) {
      columns.push(
        {
          width: 100,
          label: t('dashboard.gender'),
          dataKey: 'gender',
          cellRenderer: (genderCellRenderer),
        },
      );
    }

    columns.push({
        width: isIpad ? 100 : 200,
        flexGrow: 1,
        label: isIpad ? t('dashboard.activityShort') : t('dashboard.activity'),
        dataKey: 'status',
        cellRenderer: statusCellRenderer,
      },
      {
        width: isIpad ? 60 : 150,
        flexGrow: 1,
        label: getProgressLabel(),
        dataKey: 'testProgress',
        cellRenderer: testProgressCellRenderer,
      },
      {
        width: isIpad ? 300 : 450 ,
        flexShrink: 0,
        label: t('patient.action'),
        dataKey: 'actionType',
        cellRenderer: (actionsCellRenderer),
      });

    return (
      <VirtualizedTable
        rowCount={foundPatientActivities.length}
        rowGetter={activitiesRowGetter}
        rowRender={activitiesRowRenderer}
        rowHeight={48}
        columns={columns}
      />
    );
  };

  const patientIdCellRenderer = ({ rowData }: any) => {
    if (rowData) {
      return (
        <StyledSpan>
          <div className='patient-origin-id'>{rowData.originId}</div>
          <>(
            <div className='patient-id'>{rowData.patientId}</div>
            )
          </>
        </StyledSpan>
      );
    }
  };

  const dateTimeCellRenderer = ({ rowData }: any) => {

    return <div>{isoToDisplayDateTime(rowData.activityDateTime)}</div>;
  };

  const genderCellRenderer = ({ rowData }: any) => {

    if (rowData && rowData.sex !== null) {
      return (
        <div>{t(`gender.${rowData.sex}`)}</div>
      );
    } else {
      return (
        <div>n/a</div>
      );
    }
  };

  const statusCellRenderer = ({ rowData: { status, activityRecordType } }: any) => {

    if (activityRecordType === 'TESTING') {
      switch (status) {
        case PatientTestingStatus.OPEN:
        case PatientTestingStatus.RUNNING:
        case PatientTestingStatus.COMPLETED:
        case PatientTestingStatus.ARCHIVED:
          return <div>{isIpad ? t('patientTesting.activity.TestingShort') : t('patientTesting.activity.Testing')}</div>;
      }
    } else if (activityRecordType === 'EXERCISING') {
      switch (status) {
        case PatientTestingStatus.OPEN:
        case PatientTestingStatus.RUNNING:
        case PatientTestingStatus.COMPLETED:
        case PatientTestingStatus.ARCHIVED:
          return <div>{isIpad ? t('patientExercising.activity.ExercisingShort') : t('patientExercising.activity.Exercising')}</div>;
      }
    } else {
      return <div>{isIpad ? t('patientExercising.activity.PatientModifiedShort') : t('patientExercising.activity.PatientModified')}</div>;
    }

  };

  const getProgressLabel = () => {
    return (
      isIpad
        ? shortLabel(t('dashboard.progress'), t('dashboard.shortProgress'), true)
        : t('dashboard.progress')
    );
  };

  const testProgressCellRenderer = ({ rowData }: any) => {
    const testProgress = rowData.testProgress;
    const exerciseProgress = rowData.exerciseProgress;

    const formattedTestProgress = rowData.testProgress ? rowData.testProgress.split("(")[0] : null;
    const formattedExerciseProgress = rowData.exerciseProgress ? rowData.exerciseProgress.split("(")[0] : null;

    if (exerciseProgress) {
      return (
        <div>{!exerciseProgress ? 'n/a' : isIpad ? formattedExerciseProgress : exerciseProgress}</div>
      );
    }
    return (
      <div>{!testProgress ? 'n/a' : isIpad ? formattedTestProgress : testProgress}</div>
    );
  };

  const getQuestionSetNames = (questionSets: QuestionSetDto[]) => {
    const questionSetNames: string[] = [];

    if (questionSets.length) {
      questionSets.forEach(questionSet => {
        const questionSetNameMap: { [lang: string]: string } =
          {
            'de': questionSet.name,
            'en': questionSet.nameEn || questionSet.name,
            'fr': questionSet.nameFr || questionSet.name,
            'it': questionSet.nameIt || questionSet.name,
          };

        questionSetNames.push(questionSetNameMap[language]);
      });

      return <div>{questionSetNames.join(', ')}</div>;
    }

    return '';
  };

  const goToPreviewResults = async (patientTestingId: any, patientId: any, questionSetId: any, patientTestingQuestionSetId: any) => {

    if (patientId) {
      try {
        const patientData = await getPatientDetails(patientId, cancelTokenSource);

        push('/preview-results', {
          testDuration: testDuration,
          patientTestingQuestionSetId: patientTestingQuestionSetId,
          patientTestingId: patientTestingId,
          patient: patientData,
          questionSetId: questionSetId,
          prevPath: '/',
        });
      } catch (e: any) {
      }
    }
  };

  const sortQuestionSetsByOrderIndex = (data: QuestionSetDto[], order: number[]) => {
    const lookup = order.reduce((acc: any, id, index) => {
      acc[id] = index;
      return acc;
    }, {});

    return data.sort((qs1, qs2) => {
      const aIndex = lookup[qs1.id];
      const bIndex = lookup[qs2.id];
      return aIndex - bIndex;
    });
  };

  const actionsCellRenderer = (data: any) => {
    const {
      rowData: {
        id, actionType, patientTestingId, patientId, clinicId, testTemplateId, questionSetId, questionSetIds,
        hasAnswers, activityRecordType, patientExercisingId, exerciseTemplateId, patient, patientTestingQuestionSetId,
        hasTestResult, onlyQuestions,
      },
    } = data;

    const hasQuestions = (questionSetIds != undefined && questionSetIds.length > 0);
    const hasExport = hasTestResult || hasAnswers;

    let questionSetDtos: QuestionSetDto[] = [];
    if (questionSetIds && questionSetIds.length) {
      questionSetDtos = questionSets.filter(q => questionSetIds.includes(q.id));
      questionSetDtos = sortQuestionSetsByOrderIndex(questionSetDtos, questionSetIds);
    }
    const questionSetNames = getQuestionSetNames(questionSetDtos);

    const trigger = <PopupTriggerContainer>
      <InnerTableActionButton
        message={t(hasAnswers
          ? 'questionAnswers.updateAnswers'
          : 'patientTesting.answerQuestions')}
        onConfirm={() => push('/answer-questions',
          { patientTestingId, patientTestingQuestionSetId, patientId, prevPath: '/' },
        )}
        divider={hasExport}
      />
    </PopupTriggerContainer>;

    const renderAnswerQuestionsButton = () => {
      return (hasQuestions && !isIpad) && (
        <>
          <Popup
            trigger={trigger}
            popperDependencies={[]}
            content={questionSetNames}
            on='hover'
            size='large'
            position='top center'
          />
        </>);
    };

    const renderReportButton = (showAnyway: boolean, divider: boolean) => {
      return (showAnyway || (hasAnswers || hasTestResult)) && (
        <InnerTableActionButton
          message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
          onConfirm={() => goToPreviewResults(patientTestingId, patientId, questionSetId, patientTestingQuestionSetId)}
          divider={divider}
        />
      );
    };

    const renderExportButton = () => {
      return <>
        <InnerTableActionButton
          message={t('previewResult.export')}
          onConfirm={() => push('/export-testing', {
            patientId: patientId,
            patientTestingId: patientTestingId,
            prevPath: '/',
          })}
        />
      </>;
    };

    const deletePatientTestingArchives = (id: number) => (): void => {
      deletePatientTestingArchive(id,cancelTokenSource)
      .then(() => {
        fetchData();
      });
    };


    const renderDeletePatientTestingButton = () => {
      return(
        <>
          <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                    confirmAction={deletePatientTestingArchives(patientTestingId)}
                                    deleteConfirmationText={t('patientActivity.confirmDelete', { id: id })}
                                    position={'top left'} />
        </>
      );
    };


    if (activityRecordType === 'TESTING') {
      switch (actionType) {
        case PatientTestingActionType.CREATE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE}`)}
                onConfirm={() => openTestTemplateModal(patientId)}
              />
            </div>
          );
        case  PatientTestingActionType.START:
          return (
            <div className='row-actions'>
              {!onlyQuestions && <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.START}`)}
                onConfirm={() => push('/start-testing', { patientTestingId: patientTestingId, prevPath: '/' })}
                divider={hasQuestions && !isIpad}
              />}
              {renderAnswerQuestionsButton()}
              {renderReportButton(false, false)}
            </div>
          );
        case PatientTestingActionType.CONTINUE:
          return (
            <div className='row-actions'>
              {!onlyQuestions && <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CONTINUE}`)}
                onConfirm={() => push('/start-testing', {
                  patientTestingId: patientTestingId,
                  patientTestingQuestionSetId: patientTestingQuestionSetId,
                  questionSetId: questionSetId,
                  prevPath: '/',
                })}
                divider={true}
              />}
              {renderAnswerQuestionsButton()}
              {renderReportButton(true,true)}
              {renderExportButton()}
            </div>
          );
        case PatientTestingActionType.CREATE_REPORT:
          return (
            <div className='row-actions'>
              {!onlyQuestions && <InnerTableActionButton
                message={t('button.edit')}
                onConfirm={() => push('/start-testing', {
                  patientTestingId: patientTestingId,
                  patientTestingQuestionSetId: patientTestingQuestionSetId,
                  prevPath: '/patient/details',
                })}
                divider={true}
              />}
              {renderReportButton(true,true)}
              {
                !isIpad ?
                  <InnerTableActionButton
                    message={t('button.editTestSet')}
                    onConfirm={() => push('/patient-testing', {
                      patientTestingId, patientId, testTemplateId, prevPath: '/',
                    })}
                    divider={hasQuestions || hasExport}
                  /> : ""
              }
              {renderAnswerQuestionsButton()}
              {renderExportButton()}
            </div>
          );
        case PatientTestingActionType.ARCHIVED:
          return (
            <div className='row-actions'>
              {renderReportButton(true, hasTestResult)}
              {renderExportButton()}
              {renderDeletePatientTestingButton()}
            </div>
          );
        case PatientTestingActionType.OPEN_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.OPEN_REPORT}`)}
                onConfirm={noop}
                divider={hasExport}
              />
              {renderExportButton()}
            </div>
          );
      }
    } else if (activityRecordType === 'EXERCISING') {
      switch (actionType) {

        case PatientTestingActionType.START:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.START}`)}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId: patientId,
                  prevPath: '/',
                })}
              />
            </div>
          );
        case PatientTestingActionType.CONTINUE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CONTINUE}`)}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId,
                  prevPath: '/',
                })}
                divider={true}
              />
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => push('/preview-exercising', {
                  patientExercisingId: patientExercisingId,
                  questionSetId: questionSetId,
                  patient: patient,
                  prevPath: '/',
                })}
                divider={!isIpad}
              />
              {
                !isIpad ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId, patientId, exerciseTemplateId, prevPath: '/patient/details',
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.CREATE_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => push('/preview-exercising', {
                  patientExercisingId: patientExercisingId,
                  questionSetId: questionSetId,
                  patient: patient,
                  prevPath: '/',
                })}
                divider={!isIpad}
              />
              {
                !isIpad ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId, patientId, exerciseTemplateId, prevPath: '/',
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.ARCHIVED:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => push('/preview-exercising', {
                  patientExercisingId: patientExercisingId,
                  questionSetId: questionSetId,
                  patient: patient,
                  prevPath: '/',
                })}
                divider={false}
              />
            </div>
          );
        case PatientTestingActionType.OPEN_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.OPEN_REPORT}`)}
                onConfirm={noop}
              />
            </div>
          );
      }
    } else {
      switch (actionType) {
        case PatientTestingActionType.CREATE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE}`)}
                onConfirm={() => {
                  setModalOpen(true);
                  setSelectedPatientId(patientId);
                }
                }
                divider={true}
              />
              <InnerTableActionButton
                message={t('button.newExercising')}
                onConfirm={() => onOpenExerciseModal(patientId)}
                divider={true}
              />
              <InnerTableActionButton
                message={t('button.details')}
                onConfirm={() => goToPatientDetails(patientId, clinicId)}
              />
            </div>
          );
      }
    }
  };

  const renderTableContents = (): React.ReactNode => {
    return (
      <div className='data-table'>
        {patientActivitiesLoaded && renderRecentActivitiesTable()}
        {!patientActivitiesLoaded &&
          <Loader className='table-loader' active inline content={t('patientActivity.loading')} />}
      </div>
    );
  };

  const renderButtonsContent = (): React.ReactNode => {

    return <div className='page-actions'>
      {currentUser && currentUser.roles.includes('ROLE_APP_ADMIN') &&
        <CompositeButton
          type='button'
          className='action-button'
          primary
          onClick={onSysAdministratorClick}
          style={{ float: 'right' }}
        >
          {t('button.administrator')}
        </CompositeButton>
      }
      {currentUser && currentUser.clinicAdmin &&
        <CompositeButton
          type='button'
          className='action-button'
          primary
          onClick={onClinicAdministratorClick}
          style={{ float: 'right' }}
        >
          {t('button.clinicAdministrator')}
        </CompositeButton>
      }
    </div>;
  };

  return (
    <ViewContentWrapperDiv>
      {renderDataContent()}
      {renderTableContents()}
      {renderButtonsContent()}
      {modalOpen &&
        <TestTemplateModal
          clinicId={currentUser!.clinicId}
          patientId={selectedPatientId}
          modalOpen={modalOpen}
          prevPath={'/'}
          onCloseModal={() => setModalOpen(false)}
        />
      }
      {exerciseModalOpen &&
        <ExerciseTemplateModal
          clinicId={currentUser!.clinicId}
          patientId={selectedPatientId}
          exerciseModalOpen={exerciseModalOpen}
          prevPath={'/'}
          onCloseExerciseModal={onCloseExerciseModal}
        />
      }
    </ViewContentWrapperDiv>
  );
};

export default Dashboard;