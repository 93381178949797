import CompositeButton from 'components/final-form/CompositeButton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStopwatch } from 'react-timer-hook';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const TimerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-weight: 600;

  .ui.button {
    margin-right: 0 !important;
  }

  @media only screen and (max-width: 764px) {
    padding-top: 10px;
    padding-left: 1rem !important;
    padding-bottom: 10px;
    padding-right: 25px !important;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

interface ExtendedFieldRenderProps extends FieldRenderProps<any> {
  onStop: () => void,
  automaticTimer: boolean,
  hideTimer: boolean
}

let autoStart = false;

const FinalFormTimer = (props: ExtendedFieldRenderProps) => {

  const { t } = useTranslation('teresa');

  const { input, onStop, automaticTimer, hideTimer } = props;
  const { value, name, onChange } = input;

  const [time, setTime] = useState<number | undefined>(undefined);

  const offsetTimestamp = useCallback(() => {
    if (value) {
      const timeDate = new Date();
      timeDate.setSeconds(timeDate.getSeconds() + parseInt(value));
      return timeDate;
    }
   return undefined;
  }, [value]);

  const formatTime = (val: any) => {
    let value = val.toString();
    if (value.length < 2) {
      value = '0' + value;
    }
    return value;
  };

  const convertTimeToSeconds = () => {
    return (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
  }

  useEffect(() => {
    autoStart = false;
  }, [name])

  useEffect(() => {
    if (automaticTimer && !autoStart && !hideTimer) {
      reset(offsetTimestamp(), true);
    } else {
      reset(offsetTimestamp(), false);
    }
  }, [value, name]);

  const {
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: automaticTimer, offsetTimestamp: offsetTimestamp() });

  useEffect(() => {
    const time = convertTimeToSeconds();
    setTime(time);
  }, [hours, minutes, seconds]);

  const stopTimer = useCallback(() => {
    onChange(time);
    pause();
    autoStart = true;
    onStop();
  }, [hours, minutes, seconds, time, pause, onChange]);

  return (
    <Form.Field>
      <TimerContainer>
        <CompositeButton
          primary
          type='button'
          className='action-button'
          size='small'
          onClick={start}
          disabled={isRunning}
        >
          {t('patientTesting.timerStart').toUpperCase()}
        </CompositeButton>
        <TimeContainer>
          {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
        </TimeContainer>
        <CompositeButton
          secondary
          type='button'
          className='action-button'
          size='small'
          onClick={stopTimer}
          disabled={!isRunning}
        >
          {t('patientTesting.timerStop').toUpperCase()}
        </CompositeButton>
      </TimerContainer>
    </Form.Field>
  );
};

export default FinalFormTimer;
