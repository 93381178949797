import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface Props {
  icon: SemanticICONS,
  iconComponent?: Component,
  title: string,
  enabled: boolean,
  active: boolean,
  onClick: () => void
  subItems?: any[]
}

const SideBarItem = (props: Props) => {

  const { icon, iconComponent, title, enabled = true, onClick, active, subItems } = props;

  if (!enabled) {
    return <></>;
  }

  if (subItems && subItems.length) {
    return (
      <>
        <div className={`sideBar__item ${active ? 'active-menu-item' : ''}`}
             onClick={onClick}
        >
          <div className='item__inner'>
            {
              iconComponent
                ? iconComponent
                : <Icon name={icon} inverted />
            }
            <span>{title}</span>
          </div>
        </div>
        {subItems.map((item, index) =>
          <div key={index}
               className={`sideBar__item sub-item ${active ? 'show' : ''} ${item.active ? 'bold' : ''}`}
               onClick={item.onClick}
          >
            <div className='item__inner'>
              <span>{item.title}</span></div>
          </div>,
        )
        }
      </>
    );
  } else {
    return (
      <div className={`sideBar__item ${active ? 'active-menu-item' : ''}`}
           onClick={onClick}
      >
        <div className='item__inner'>
          {
            iconComponent
              ? iconComponent
              : <Icon name={icon} inverted />
          }
          <span>{title}</span></div>
      </div>
    );
  }
};

export default SideBarItem;
