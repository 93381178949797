import { useAuthContext } from 'auth/AuthContext';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import InputView from 'components/InputView';
import LoaderComponent from 'components/LoaderComponent';
import SendActivationKeyPopup from 'components/SendActivationKeyPopup';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import ViewContentWrapperDiv from 'components/ViewContentWrapperDiv';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ExerciseTemplateModal from 'routes/patientexercising/ExerciseTemplateModal';
import TestTemplateModal from 'routes/patienttesting/TestTemplateModal';
import { Grid, Popup } from 'semantic-ui-react';
import { deactivateAdmin, generateRegKey, resendInvitation } from 'service/accountService';
import { getAllQuestionSets } from 'service/teresaConfService';
import { getDoctorDetails } from 'service/doctorService';
import axios from 'service/http';
import { getPatientDetails } from 'service/patientService';
import { getRecentActivities } from 'service/patientTestingService';
import styled from 'styled-components';
import { PatientTestingActionType } from 'ts-types/api.enums';
import {
  DoctorDto,
  PatientTestingActivityResponse,
  QuestionSetDto,
  TriggerAccountActivationRequest,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { shortLabel } from 'util/tableUtils';

interface RouteParams {
  doctorId?: string;
}

const StyledSpan = styled.span`
  display: flex;
  
  .patient-origin-id{
    margin-right: 0.5rem;
  }
  
  .patient-id {
    color: blue;
  }
`;

const PopupTriggerContainer = styled.div`
  display: inline-block;
`;

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
}

const cancelTokenSource = axios.CancelToken.source();

const DoctorDetailsView = (props: Props) => {
  const { state } = useLocation();
  const doctorId: number | undefined = state?.id
    ? Number(state?.id)
    : undefined;

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { language } = useAuthContext();
  const { currentUser } = useAuthContext();
  const testDuration: string | undefined = state?.testDuration ? String(state?.testDuration) : undefined;
  const isIpad = useIsIpadWidthOrBelow();

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const [doctor, setDoctor] = React.useState<DoctorDto | undefined>(undefined);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [successMessage, setSuccessMessage]
    = useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [foundPatientActivities, setFoundPatientActivities] = useState<PatientTestingActivityResponse[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<number | undefined>(undefined);
  const [questionSets, setQuestionSets] = useState<QuestionSetDto[]>([]);
  const [exerciseModalOpen, setExerciseModalOpen] = useState<boolean>(false);

  const {setClinicId} = useClinicHeaderContext();

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    setDataLoaded(false);
    if (doctorId) {
      try {
        const doctorData = await getDoctorDetails(doctorId, cancelTokenSource);
        setDoctor(doctorData);
        const patientActivities = await getRecentActivities(doctorId, cancelTokenSource);
        setFoundPatientActivities(patientActivities);
        const questionSetResponses = await getAllQuestionSets(cancelTokenSource);
        setQuestionSets(questionSetResponses);
      } catch (e: any) {
        handleError(e.response.data);
      } finally {
        setDataLoaded(true);
      }
    }
  }, []);

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const onCloseExerciseModal = () => {
    setExerciseModalOpen(false);
  };

  const goToPreviousPage = () => {
    push('/clinic/details',  isSystemAdmin && { id: doctor!.clinicId, clinicId: doctor?.clinicId});
  };

  const goToUpsertDoctor = useCallback(() => {
    push(`/doctor`, { id: doctorId, clinicId: state.clinicId });
  }, []);

  const deactivateAccount = (accountId: number, successMessage: string) => {
    deactivateAdmin(accountId).then(() => {
      setSuccessMessage(successMessage);
      fetchData();
    })
    .catch((e) => handleError(e.response.data));
  };

  const resendActivation = (accountId: number, request: Partial<TriggerAccountActivationRequest>) => {
    resendInvitation(accountId, request)
    .then(() => {
      setSuccessMessage(t("doctor.details.resentActivation.successMessage"));
      fetchData();
    })
    .catch((e) => handleError(e.response.data));
  }

  const activateAccount = (accountId: number, request: Partial<TriggerAccountActivationRequest>) => {
    generateRegKey(accountId, request)
    .then(() => {
      setSuccessMessage(t("doctor.details.activation.successMessage"));
      fetchData();
    })
    .catch((e) => handleError(e.response.data));
  };

  const popupContent = (
    id: number,
    resend: boolean,
    func: (id: number, request: Partial<TriggerAccountActivationRequest>) => void,
    email?: string) => {

    return {
      id: id,
      resend: resend,
      func: func,
      email: email,
    };
  };

  const openTestTemplateModal = (patientId: number) => {
    setSelectedPatientId(patientId);
    setModalOpen(true);
  };

  const onOpenExerciseModal = (patiendId: number) => {
    setSelectedPatientId(patiendId);
    setExerciseModalOpen(true);
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.doctorNotFound,
        errorUtils.accountNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  }, []);

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  }, []);

  const renderPatientDetails = () => {
    if (doctor) {

      const accountId: number = doctor.accountId;
      const accountActivationRequest: TriggerAccountActivationRequest = {
        email: doctor.email
      };

      return (
        <>
          <TsaGrid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='title-h1'>{t('doctor.details.title')}</div>
              </Grid.Column>
            </Grid.Row>

            {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='error'>
                    <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                      {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
            }

            {successMessage &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage color='green' onDismiss={() => setSuccessMessage(undefined)}>
                    {successMessage}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={16}>
                <Grid stackable doubling columns={2}>
                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.doctorId')}</DataLabel>
                          <InputView>{doctor.id}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.title')}</DataLabel>
                          <InputView>{doctor.title}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.name')}</DataLabel>
                          <InputView>{doctor.name}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.lastName')}</DataLabel>
                          <InputView>{doctor.lastName}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.staffType')}</DataLabel>
                          <InputView>{doctor.staffType}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.userName')}</DataLabel>
                          <InputView>{doctor.userName}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.tel')}</DataLabel>
                          <InputView>{doctor.tel}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.email')}</DataLabel>
                          <InputView>{doctor.email}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.details.city')}</DataLabel>
                          <InputView>{doctor.city}</InputView>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <CompositeButton
                            type='button'
                            className='action-button'
                            primary
                            onClick={goToUpsertDoctor}
                          >
                            {t('doctor.details.updateButton')}
                          </CompositeButton>
                        </Grid.Column>
                      </Grid.Row>

                      { doctor.accountActive
                        ? <Grid.Row>
                            <Grid.Column width={16}>
                              <CompositeButton
                                type='button'
                                className='action-button'
                                primary
                                onClick={() => deactivateAccount(
                                  accountId,
                                  t("doctor.details.deactivateAccount.successMessage")
                                )}
                              >
                                {t('doctor.deactivate')}
                              </CompositeButton>
                            </Grid.Column>
                         </Grid.Row>
                        : !!doctor.regKey
                          ? <React.Fragment>
                              <Grid.Row>
                                <Grid.Column width={16}>
                                  <CompositeButton
                                    type='button'
                                    className='action-button'
                                    primary
                                    onClick={() => deactivateAccount(
                                      accountId,
                                      t("doctor.details.removeKey.successMessage")
                                    )}
                                  >
                                    {t('doctor.removeKey')}
                                  </CompositeButton>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={16}>
                                  <SendActivationKeyPopup
                                    triggerButtonText='doctor.resendInv'
                                    content={popupContent(accountId, true, resendActivation, doctor.email)}
                                    position='left center'
                                    tableButton={false}
                                    displayModal={isIpad}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                          </React.Fragment>
                          : <Grid.Row>
                            <Grid.Column width={16}>
                              <SendActivationKeyPopup
                                triggerButtonText='doctor.activate'
                                content={popupContent(accountId, true, activateAccount, doctor.email)}
                                position='left center'
                                tableButton={false}
                                displayModal={isIpad}
                              />
                            </Grid.Column>
                          </Grid.Row>

                      }
                    </InnerTsaGrid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </>
      );
    }
  };

  const activitiesRowGetter = ({ index }: any) => {

    if (foundPatientActivities.length > 0) {
      Object.assign(foundPatientActivities[index], { index: index + 1 });

      return foundPatientActivities[index];
    }
  };

  const activitiesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const renderRecentActivitiesTable = (): JSX.Element => {

    let columns: React.ReactNode[] = [
      {
        width: 150,
        flexShrink: 0,
        label: t('dashboard.dateTime'),
        dataKey: 'activityDateTime',
        cellRenderer: (dateTimeCellRenderer),
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('dashboard.patientId'),
        dataKey: 'patientId',
        cellRenderer: (patientIdCellRenderer),
      },
    ];

    if (!isIpad) {
      columns.push(
        {
          width: 300,
          flexGrow: 1,
          label: t('dashboard.activity'),
          dataKey: 'status',
          cellRenderer: (statusCellRenderer),
        },
        {
          width: 100,
          label: t('dashboard.gender'),
          dataKey: 'gender',
          cellRenderer: (genderCellRenderer),
        },
      );
    }

    columns.push(
      {
        width: isIpad ? 60 : 150,
        flexGrow: 1,
        label: getProgressLabel(),
        dataKey: 'testProgress',
        cellRenderer: testProgressCellRenderer,
      },
      {
        width: isIpad ? 280 : 450,
        flexShrink: 0,
        label: t('patient.action'),
        dataKey: 'actionType',
        cellRenderer: (actionsCellRenderer),
      },
    );

    return (
      <>
        <div className='data-table'>
          <VirtualizedTable
            rowCount={foundPatientActivities.length}
            rowGetter={activitiesRowGetter}
            rowRender={activitiesRowRenderer}
            rowHeight={48}
            columns={columns}
          />
        </div>
        <div className='page-actions'>
          <CompositeButton
            type='button'
            className='action-button'
            secondary
            onClick={goToPreviousPage}
            style={{ float: 'right' }}
          >
            {t('action.back')}
          </CompositeButton>
        </div>
      </>
    );
  };

  const patientIdCellRenderer = ({ rowData }: any) => {
    if (rowData) {
      return (
        <StyledSpan>
          <div className='patient-origin-id'>{rowData.originId}</div>
          <>(
            <div className='patient-id'>{rowData.patientId}</div>
            )
          </>
        </StyledSpan>
      );
    }
  };

  const dateTimeCellRenderer = ({ rowData }: any) => {
    const date = moment(rowData.activityDateTime).format('MMM DD, YYYY - h:mm');

    return <div>{`${date}`}</div>;
  };

  const genderCellRenderer = ({ rowData }: any) => {

    if (rowData && rowData.sex !== null) {
      return (
        <div>{t(`gender.${rowData.sex}`)}</div>
      );
    } else {
      return (
        <div>n/a</div>
      );
    }
  };

  const statusCellRenderer = ({ rowData }: any) => {

    if (rowData.activityRecordType === 'TESTING') {
      return <div>{t('patientTesting.activity.Testing')}</div>;
    } else if (rowData.activityRecordType === 'EXERCISING') {
      return <div>{t('patientExercising.activity.Exercising')}</div>;
    } else {
      return <div>{t('patientExercising.activity.PatientModified')}</div>;
    }
  };

  const getProgressLabel = () => {
    return (
      isIpad
        ? shortLabel(t('dashboard.progress'), t('dashboard.shortProgress'), true)
        : t('dashboard.progress')
    );
  };

  const testProgressCellRenderer = ({ rowData }: any) => {
    const testProgress = rowData.testProgress;
    const formattedTestProgress = rowData.testProgress ? rowData.testProgress.split("(")[0] : null;

    return (
      <div>{!testProgress ? 'n/a' : isIpad ? formattedTestProgress : testProgress}</div>
    );
  };

  const goToPreviewResults = async (patientTestingId: any, patientId: any, questionSetId: any, patientTestingQuestionSetId: any) => {

    if(patientId){
      try {
        const patientData = await getPatientDetails(patientId, cancelTokenSource);

        push('/preview-results', {
          testDuration: testDuration,
          patientTestingQuestionSetId: patientTestingQuestionSetId,
          patientTestingId: patientTestingId,
          patient: patientData,
          questionSetId: questionSetId,
          prevPath: '/doctor/details',
          clinicId: state.clinicId
        })
      } catch (e: any) {
      }
    }
  }

  const goToPreviewExercises = async (patientExercisingId: any, patientId: any, questionSetId: any) => {

    if(patientId){
      try {
        const patientData = await getPatientDetails(patientId, cancelTokenSource);

        push('/preview-exercising', {
          patientExercisingId: patientExercisingId,
          questionSetId: questionSetId,
          patient: patientData,
          doctorId: doctorId,
          patientId,
          prevPath: '/doctor/details',
          clinicId: state.clinicId
        })
      } catch (e: any) {
      }
    }
  }

  const getQuestionSetNames = (questionSets: QuestionSetDto[]) => {
    const questionSetNames: string[] = [];

    if (questionSets.length) {
      questionSets.forEach(questionSet => {
        const questionSetNameMap: { [lang: string]: string } =
          {
            'de': questionSet.name,
            'en': questionSet.nameEn || questionSet.name,
            'fr': questionSet.nameFr || questionSet.name,
            'it': questionSet.nameIt || questionSet.name,
          };

        questionSetNames.push(questionSetNameMap[language]);
      });

      return <div>{questionSetNames.join(', ')}</div>;
    }

    return '';
  };

  const sortQuestionSetsByOrderIndex = (data: QuestionSetDto[], order: number[]) => {
    const lookup = order.reduce((acc: any, id, index) => {
      acc[id] = index;
      return acc;
    }, {});

    return data.sort((qs1, qs2) => {
      const aIndex = lookup[qs1.id];
      const bIndex = lookup[qs2.id];
      return aIndex - bIndex;
    });
  }

  const actionsCellRenderer = (data: any) => {
    const { rowData: { actionType, patientTestingId, patientId, questionSetIds, hasAnswers,
      patientTestingQuestionSetId, hasTestResult, questionSetId, testTemplateId, activityRecordType, patientExercisingId,
      exerciseTemplateId, patient } } = data;

    const hasQuestions = (questionSetIds != undefined && questionSetIds.length > 0);
    const hasExport = hasTestResult || hasAnswers;

    const renderExportButton = () => {
      return <>
          <InnerTableActionButton
            message={t('previewResult.export')}
            onConfirm={() => push('/export-testing', {
              patientId: patientId,
              patientTestingId: patientTestingId,
              prevPath: '/doctor/details',
              clinicId: state.clinicId
            })}
          />
        </>;
    };


    let questionSetDtos: QuestionSetDto[] = [];
    if (questionSetIds && questionSetIds.length) {
      questionSetDtos =  questionSets.filter(q => questionSetIds.includes(q.id));
      questionSetDtos = sortQuestionSetsByOrderIndex(questionSetDtos, questionSetIds);
    }
    const questionSetNames = getQuestionSetNames(questionSetDtos);


    const trigger = <PopupTriggerContainer>
      <InnerTableActionButton
        message={t(hasAnswers
          ? 'questionAnswers.updateAnswers'
          : 'patientTesting.answerQuestions')}
        onConfirm={() => push('/answer-questions',
          {
            patientTestingId,
            patientTestingQuestionSetId,
            patientId,
            prevPath: '/doctor/details',
            clinicId: state.clinicId
          },
        )}
        divider={hasExport}
      />
    </PopupTriggerContainer>

    const renderAnswerQuestionsButton = () => {
      return (hasQuestions && !isIpad) && (
        <Popup
          trigger={trigger}
          popperModifiers={[{ name: 'preventOverflow', options: {boundary: 'patientDetailsTable'} }]}
          content={questionSetNames}
          on='hover'
          size='small'
          position='top center'
        />
      );
    };

    if (activityRecordType === 'TESTING') {
      switch (actionType) {
        case PatientTestingActionType.CREATE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE}`)}
                onConfirm={() => openTestTemplateModal(patientId)}
              />
            </div>
          );
        case  PatientTestingActionType.START:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.START}`)}
                onConfirm={() => push('/start-testing',
                  { patientTestingId: patientTestingId,
                    prevPath: '/doctor/details',
                    clinicId: state.clinicId
                  })}
                divider={hasQuestions && !isIpad}
              />
              {renderAnswerQuestionsButton()}
            </div>
          );
        case PatientTestingActionType.CONTINUE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CONTINUE}`)}
                onConfirm={() => push('/start-testing',
                  { patientTestingId: patientTestingId,
                    patientTestingQuestionSetId: patientTestingQuestionSetId,
                    questionSetId: questionSetId,
                    prevPath: '/doctor/details',
                    clinicId: state.clinicId
                  })}
                divider={hasQuestions || hasExport}
              />
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => goToPreviewResults(patientTestingId, patientId, questionSetId, patientTestingQuestionSetId)}
                divider={true}
              />
              {renderAnswerQuestionsButton()}
              {renderExportButton()}
            </div>
          );
        case PatientTestingActionType.CREATE_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t('button.edit')}
                onConfirm={() => push('/start-testing', {
                  patientTestingId: patientTestingId,
                  patientTestingQuestionSetId: patientTestingQuestionSetId,
                  prevPath: '/patient/details',
                  clinicId: state.clinicId
                })}
                divider={true}
              />
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => goToPreviewResults(patientTestingId, patientId, questionSetId, patientTestingQuestionSetId)}
                divider={true}
              />
               {
                !isIpad ?
                  <InnerTableActionButton
                    message={t('button.editTestSet')}
                    onConfirm={() => push('/patient-testing', {
                      patientTestingId, patientId, testTemplateId, prevPath: '/doctor/details',clinicId: state.clinicId
                    })}
                    divider={hasQuestions || hasExport}
                  /> : ""
              }
              {renderAnswerQuestionsButton()}
              {renderExportButton()}
            </div>
          );
        case PatientTestingActionType.ARCHIVED:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => goToPreviewResults(patientTestingId, patientId, questionSetId, patientTestingQuestionSetId)}
                divider={hasExport}
              />
              {renderExportButton()}
            </div>
          );
        case PatientTestingActionType.OPEN_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientTesting.actionType.${PatientTestingActionType.OPEN_REPORT}`)}
                onConfirm={noop}
                divider={hasExport}
              />
              {renderExportButton()}
            </div>
          );
      }
    } else if (activityRecordType === 'EXERCISING') {
      switch (actionType) {

        case PatientTestingActionType.START:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.START}`)}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId: patientId,
                  prevPath: '/doctor/details',
                  clinicId: state.clinicId
                })}
              />
            </div>
          );
        case PatientTestingActionType.CONTINUE:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CONTINUE}`)}
                onConfirm={() => push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId,
                  prevPath: '/doctor/details',
                  clinicId: state.clinicId
                })}
                divider={true}
              />
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => goToPreviewExercises(patientExercisingId, patientId, questionSetId)}
                divider={!isIpad}
              />
              {
                !isIpad ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId, patientId, exerciseTemplateId, prevPath: '/doctor/details',clinicId: state.clinicId
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.CREATE_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => goToPreviewExercises(patientExercisingId, patientId, questionSetId)}
                divider={!isIpad}
              />
              {
                !isIpad ?
                  <InnerTableActionButton
                    message={t('button.editExerciseSet')}
                    onConfirm={() => push('/exercise-testing', {
                      patientExercisingId, patientId, exerciseTemplateId, prevPath: '/doctor/details',clinicId: state.clinicId
                    })}
                  /> : ""
              }
            </div>
          );
        case PatientTestingActionType.ARCHIVED:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
                onConfirm={() => push('/preview-exercising', {
                  patientExercisingId: patientExercisingId,
                  questionSetId: questionSetId,
                  patient: patient,
                  prevPath: '/doctor/details',
                  clinicId: state.clinicId
                })}
                divider={false}
              />
            </div>
          );
        case PatientTestingActionType.OPEN_REPORT:
          return (
            <div className='row-actions'>
              <InnerTableActionButton
                message={t(`patientExercising.actionType.${PatientTestingActionType.OPEN_REPORT}`)}
                onConfirm={noop}
              />
            </div>
          );
      }
    } else {
        switch (actionType) {
          case PatientTestingActionType.CREATE:
            return (
              <div className='row-actions'>
                <InnerTableActionButton
                  message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE}`)}
                  onConfirm={() => {
                    setModalOpen(true)
                    setSelectedPatientId(patientId)}
                  }
                  divider={true}
                />
                <InnerTableActionButton
                  message={t('button.newExercising')}
                  onConfirm={() => onOpenExerciseModal(patientId)}
                />
              </div>
            );
        }
      }
    };

  return (
    <ViewContentWrapperDiv>
      {dataLoaded
        ? <React.Fragment>
          {renderPatientDetails()}
          {renderRecentActivitiesTable()}
        </React.Fragment>
        : <LoaderComponent message={t('doctor.loading')} />
      }
      {modalOpen &&
        <TestTemplateModal
          clinicId={doctor!.clinicId}
          doctorId={doctor?.id}
          patientId={selectedPatientId}
          modalOpen={modalOpen}
          prevPath={'/doctor/details'}
          onCloseModal={onCloseModal}
        />
      }
      {exerciseModalOpen &&
        <ExerciseTemplateModal
          clinicId={doctor!.clinicId}
          patientId={selectedPatientId}
          exerciseModalOpen={exerciseModalOpen}
          prevPath={'/doctor/details'}
          onCloseExerciseModal={onCloseExerciseModal}
        />
      }
    </ViewContentWrapperDiv>
  );
};

export default DoctorDetailsView;