import { useAuthContext } from 'auth/AuthContext';
import CheckBox from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { DropdownOption, stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import { adminAddDoctor, adminEditDoctor } from 'service/adminService';
import { addDoctor, editDoctor, findDoctorById } from 'service/doctorService';
import axios from 'service/http';
import { StaffType } from 'ts-types/api.enums';
import { UpsertDoctorDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { composeValidators, emailValidator, required } from 'util/validatorUtils';

interface ExtendedUpsertDoctorDto extends UpsertDoctorDto {
  clinicAdmin: boolean;
}

interface Props {
}

const UpsertDoctorForm = (props: Props) => {

    const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);

    const {setClinicId} = useClinicHeaderContext();

    const { currentUser } = useAuthContext();

    const { state } = useLocation();
    const doctorId: number | undefined = state?.id ? Number(state.id) : undefined;
    const clinicId: number | undefined = state?.clinicId ? Number(state.clinicId) : undefined;

    const { t } = useTranslation('teresa');
    const { goBack } = useHistory();

    const isSystemAdmin = useMemo(() => currentUser
      && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

    const isAdmin = useMemo(() => currentUser
      && currentUser.clinicAdmin, [currentUser]);

    const languageLabels: Array<string> = ['german', 'french', 'italian', 'english'];
    const languageKeys: Array<string> = ['de', 'fr', 'it', 'en'];

    const languages = useMemo(() =>
      stringArrayToDropdownOptionArray(languageLabels, t, 'language', languageKeys), []);

    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const staffTypes: Array<DropdownOption> = Object.values(StaffType)
    .map((staffTypes, index): DropdownOption => (
      {
        key: index,
        text: t(`staffType.${staffTypes}`),
        value: staffTypes,
      }
    ));

    const initialDoctorValues: Partial<ExtendedUpsertDoctorDto> = {
      language: 'de',
      active: true,
      clinicAdmin: false,
    };

    const [doctor, setDoctor] = React.useState<Partial<ExtendedUpsertDoctorDto> | undefined>(initialDoctorValues);
    const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
    const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);

    useEffect(() => {
      setClinicId(clinicId);
      if (doctorId) {
        setFormDataLoaded(false);
        findDoctorById(doctorId, cancelTokenSource)
        .then(response => {
          const upsertDoctorDto: Partial<ExtendedUpsertDoctorDto> = {
            ...response,
          };
          setDoctor(upsertDoctorDto);
        })
        .catch((e: any) => handleError(e.response.data))
        .finally(() => setFormDataLoaded(true));
      }
    }, []);

    const goToPreviousPage = useCallback(() => {
      goBack();
    }, []);

    const handleError = useCallback((error: any) => {

      if (error) {
        let errMsgs = [];
        const errorCode = error.errorCode;
        const knownErrors: Array<string> = [
          errorUtils.invalidEmail,
          errorUtils.doctorNotFound,
          errorUtils.accountNotFound,
          errorUtils.usernameAlreadyExists,
          errorUtils.emailAlreadyInuse,
        ];

        const violations: Array<any> = error.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              errMsgs.push(t(`error.${violation.errorCode}`));
            }
          });
        }

        if (!errMsgs.length) {
          if (knownErrors.includes(errorCode)) {
            errMsgs.push(t(`error.${errorCode}`));
          } else {
            errMsgs.push(t('error.general'));
          }
        }

        setErrorMessage(errMsgs);
      }

    }, []);

    const setErrorMessage = (errMsgs?: string[]) => {

      if (errMsgs) {
        setErrorMessages(errMsgs);
      } else {
        setErrorMessages([]);
      }
    };

    const handleSubmit = (values: Partial<ExtendedUpsertDoctorDto>) => {

      let saveValues: Partial<UpsertDoctorDto> = {
        ...values,
      };

      const onSave = () => {
        doctorId
          ? setSuccessMsg(t('doctor.onUpdate'))
          : setSuccessMsg(t('doctor.onAdd'));
        setTimeout(() => {
          goBack();
        }, 1200);
      };

      if (isSystemAdmin && clinicId) {
        if (doctorId) {
          adminEditDoctor(clinicId, doctorId, saveValues, cancelTokenSource)
          .then(onSave)
          .catch((e: any) => handleError(e.response.data));
        } else {
          adminAddDoctor(clinicId, saveValues, cancelTokenSource)
          .then(onSave)
          .catch((e: any) => handleError(e.response.data));
        }
        return;
      }

      if (doctorId) {
        editDoctor(doctorId, saveValues, cancelTokenSource)
        .then(onSave)
        .catch((e: any) => handleError(e.response.data));
      } else {
        addDoctor(saveValues, cancelTokenSource)
        .then(onSave)
        .catch((e: any) => handleError(e.response.data));
      }
    };

    const renderFinalForm = (): React.ReactNode => {
      return (
        <FinalForm
          onSubmit={(values: Partial<UpsertDoctorDto>) => handleSubmit(values)}
          initialValues={doctor}
          subscription={{ pristine: true, submitting: true }}
          render={(formProps: FormRenderProps<UpsertDoctorDto>) => renderFormContent(formProps)}
        />
      );
    };

    const renderFormContent = (
      { handleSubmit, submitting }: FormRenderProps<UpsertDoctorDto>): React.ReactNode => {

      const disableBtns = !isSystemAdmin && !isAdmin;

      return (
        <form onSubmit={handleSubmit}>
          <TsaGrid>
            <Grid.Row>
              <Grid.Column width={12}>
                <div className='title-h1'>{t('doctor.viewTitle')}</div>
              </Grid.Column>
            </Grid.Row>

            {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={12}>
                  <div className='error'>
                    <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                      {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={16}>
                <Grid stackable doubling columns={2}>
                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as='h4'>{t('patient.generalInfo')}</Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.title')}</DataLabel>
                          <Field
                            fluid
                            name='title'
                            component={Input}
                            autoFocus
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.name')}</DataLabel>
                          <Field
                            fluid
                            name='name'
                            component={Input}
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.lastName')}</DataLabel>
                          <Field
                            fluid
                            name='lastName'
                            component={Input}
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.userName')}</DataLabel>
                          <Field
                            fluid
                            name='userName'
                            component={Input}
                            validate={required}
                            disabled={!!doctorId}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.language')}</DataLabel>
                          <Field
                            fluid
                            name='language'
                            component={Select}
                            options={languages}
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.status')}</DataLabel>
                          <Field
                            name='active'
                            component={CheckBox}
                            label={t('doctor.active')}
                            toggle
                            disabled={disableBtns}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row></Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.staffType')}</DataLabel>
                          <Field
                            fluid
                            name='staffType'
                            component={Select}
                            options={staffTypes}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.street')}</DataLabel>
                          <Field
                            fluid
                            name='street'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.zip')}</DataLabel>
                          <Field
                            fluid
                            name='zip'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.city')}</DataLabel>
                          <Field
                            fluid
                            name='city'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>&nbsp;</DataLabel>
                          <Field
                            name='clinicAdmin'
                            component={CheckBox}
                            label={t('doctor.admin')}
                            disabled={disableBtns}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>

                        <Grid.Column width={16}>
                          <Header as='h4'>{t('patient.contactInfo')}</Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.tel')}</DataLabel>
                          <Field
                            fluid
                            name='tel'
                            component={Input}
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('doctor.email')}</DataLabel>
                          <Field
                            fluid
                            name='email'
                            component={Input}
                            validate={composeValidators(required, emailValidator)}
                          />
                        </Grid.Column>
                      </Grid.Row>

                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Row>
                    <Grid.Column width={12}
                                 style={{
                                   borderTop: '1px solid var(--very-light-blue)',
                                   marginTop: '2rem',
                                 }}
                    >
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign='right'>
              <Grid.Column width={12} style={{ paddingRight: '0' }}>
                {
                  successMsg &&
                  <SaveAndUpdateConfirmationMessage>
                    {successMsg}
                  </SaveAndUpdateConfirmationMessage>
                }
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={noop}
                >
                  {t('button.save')}
                </CompositeButton>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  disabled={submitting || !!successMsg}
                  onClick={goToPreviousPage}
                  style={{ marginRight: 0 }}
                >
                  {t('action.back')}
                </CompositeButton>
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </form>
      );
    };

    return (
      <UpsertContentWrapperDiv>
        {formDataLoaded
          ? <React.Fragment>
            {renderFinalForm()}
          </React.Fragment>
          : <LoaderComponent message={t('doctor.loading')} />
        }
      </UpsertContentWrapperDiv>
    );
  }
;

export default UpsertDoctorForm;