import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import ImageDropZone from 'components/final-form/ImageDropZone';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  stringArrayToDropdownOptionArray,
} from 'components/final-form/Select';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { noop, toUpper } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import { adminEditClinic, getClinicById } from 'service/adminService';
import { addClinic, editClinic, findAdminClinic } from 'service/clinicService';
import { searchMandates } from 'service/mandateServices';
import { InvoiceConfigType } from 'ts-types/api.enums';
import { MandateSearchRequest, UpsertClinicDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { composeValidators, emailValidator, maxChars, required, telephoneValidator } from 'util/validatorUtils';

interface ExtendedUpsertClinicDto extends UpsertClinicDto {
  activeStatus: number;
}

interface Props {

}

const cancelTokenSource = axios.CancelToken.source();

const UpsertClinicForm = (props: Props) => {

  const { currentUser } = useAuthContext();

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isTestAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_TEST_ADMIN'), [currentUser]);

  const isClinicAdmin = useMemo(() => currentUser
    && currentUser.clinicAdmin, [currentUser]);

  const { state } = useLocation();
  const clinicId: number | undefined = state?.id ? Number(state.id) : undefined;

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { setClinicId } = useClinicInfoContext();

  const invoicePackageTypeLabels: Array<string> = useMemo(() => Object.values(InvoiceConfigType), []);

  const invoicePackageTypes = useMemo(() =>
    stringArrayToDropdownOptionArray(invoicePackageTypeLabels, t, 'invoicePackageType'), []);

  const initialClinicValues: Partial<ExtendedUpsertClinicDto> = {
    active: true,
    activeStatus: 1,
  };

  const [clinic, setClinic] = React.useState<Partial<ExtendedUpsertClinicDto> | undefined>(initialClinicValues);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [clinicMandates, setClinicMandates] = useState<DropdownOption[]>([]);
  useState<number | undefined>(undefined);

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchFormData();
  }, []);

  const fetchFormData = async () => {

    const onFinally = () => setFormDataLoaded(true);

    setFormDataLoaded(false);

    let foundClinic = { ...clinic };

    try {
      if (isClinicAdmin) {
        foundClinic = await findAdminClinic(cancelTokenSource);
      } else {
        if (isSystemAdmin) {
          if (clinicId) {
            foundClinic = await getClinicById(clinicId, cancelTokenSource);
          }

          let newSearchValues: Partial<MandateSearchRequest> = {
            searchKey: '',
            active: true,
          };

          const mandates = await searchMandates(newSearchValues, cancelTokenSource);

          let mandateDropDownOptions: DropdownOption[] = [];
          if (mandates.length) {
            mandateDropDownOptions = mapToDropdownOptionArray(mandates, 'name', 'id', 'id');
            setClinicMandates(mandateDropDownOptions);
          }
        }
      }
    } catch (e) {

    } finally {
      setEditClinic(foundClinic);
      onFinally();
    }
  };

  const setEditClinic = useCallback((upsertClinicDto: Partial<UpsertClinicDto>) => {
    const clinic: Partial<ExtendedUpsertClinicDto> = {
      ...upsertClinicDto,
      activeStatus: upsertClinicDto.active ? 1 : 0,
    };
    setClinic(clinic);
  }, []);

  const goToPreviousPage = useCallback(() => {

    const clinicSearch = '/';
    const clinicDetails = '/clinic/details';

    if (!clinicId) {
      push(clinicSearch);
    } else {
      if (isSystemAdmin) {
        push(clinicDetails, { id: clinicId, clinicId: state.clinicId });
      } else {
        push(clinicDetails, { clinicId: state.clinicId });
      }
    }
  }, [clinicId]);

  const onBackButtonClick = () => {
    if (isSystemAdmin) {
      goToPreviousPage();
    } else if (isTestAdmin && isClinicAdmin) {
      push('/clinic/details', { clinicId: state.clinicId });
    } else {
      push('/');
    }
  };

  const handleError = useCallback((error: any) => {
    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.invalidEmail,
        errorUtils.clinicNotFound,
        errorUtils.doctorNotFound,
        errorUtils.clinicCodeInvalidFormat,
        errorUtils.clinicCodeNull
      ];

      const violations: Array<any> = error.violations;
      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const getFineInvalidTypeError = (errorCode: string) => {
    if (errorCode === 'file-invalid-type') {
      setErrorMessage([t(`error.${errorUtils.fileTypeInvalid}`)]);
    }
  };

  const imageSizeExceeded = useCallback((values: Partial<ExtendedUpsertClinicDto>) => {

    if (values.image && values.image.size >= 1048576) {

      setErrorMessages([t(`error.${errorUtils.fileSizeExceeded}`)]);

      return true;
    }

    return false;
  }, []);

  const handleSubmit = useCallback(async (values: Partial<ExtendedUpsertClinicDto>) => {

    let saveValues: Partial<UpsertClinicDto> = {
      ...values,
      active: values.activeStatus === 1,
    };

    const imageSizeExceed = imageSizeExceeded(values);

    if (imageSizeExceed) {
      return;
    }

    const onSave = () => {
      isClinicAdmin || clinicId || isSystemAdmin
        ? setSuccessMsg(t('clinic.onUpdate'))
        : setSuccessMsg(t('clinic.onAdd'));
      setTimeout(() => {
        if (isClinicAdmin || clinicId) {
          goToPreviousPage();
        } else if (isSystemAdmin) {
          goToPreviousPage();
        } else {
          push('/');
        }
      }, 1200);
    };

    if (isSystemAdmin && clinicId) {
      await adminEditClinic(clinicId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else if (isClinicAdmin) {
      await editClinic(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addClinic(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }

  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertClinicDto) => handleSubmit(values)}
        initialValues={clinic}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertClinicDto>) => renderFormContent(formProps)}
      />
    );
  };
  const renderFormContent = ({
                               handleSubmit,
                               submitting,
                               form,
                               values,
                             }: FormRenderProps<UpsertClinicDto>): React.ReactNode => {

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className='title-h1'>{t('clinic.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          {
            errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={12}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as='h4'>{t(`patient.generalInfo`)}</Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.name')}</DataLabel>
                          <Field
                            fluid
                            name='name'
                            component={Input}
                            validate={required}
                            autoFocus
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.code')}</DataLabel>
                          <Field
                            fluid
                            name='clinicCode'
                            component={Input}
                            validate={required}
                            disabled={!isSystemAdmin}
                            format={toUpper}
                            autoFocus
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.street')}</DataLabel>
                          <Field
                            fluid
                            name='street'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.zip')}</DataLabel>
                          <Field
                            fluid
                            name='zip'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.city')}</DataLabel>
                          <Field
                            fluid
                            name='city'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      {isSystemAdmin &&
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <DataLabel>{t('clinic.mandate')}</DataLabel>
                            <Field
                              fluid
                              name='mandateId'
                              component={Select}
                              options={clinicMandates}
                              search
                              clearable
                              disabled={!isSystemAdmin}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      }

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinicConf.invoicePackageType')}</DataLabel>
                          <Field
                            name='invoicePackageType'
                            component={Select}
                            options={invoicePackageTypes}
                            clearable
                            fluid
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.status')}</DataLabel>
                          <Field
                            name='active'
                            component={CheckBox}
                            label={t('clinic.active')}
                            toggle={true}
                          />
                        </Grid.Column>
                      </Grid.Row>

                    </InnerTsaGrid>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as='h4'>{t(`patient.contactInfo`)}</Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.tel')}</DataLabel>
                          <Field
                            fluid
                            name='tel'
                            component={Input}
                            validate={composeValidators(telephoneValidator(t), maxChars(155))}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.email')}</DataLabel>
                          <Field
                            fluid
                            name='email'
                            component={Input}
                            validate={emailValidator}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={5}>
                          <DataLabel>{t('clinic.reportsLogo')}</DataLabel>
                          <Field name='image'
                                 component={ImageDropZone}
                                 imageView={values.image ? undefined : values.imageView}
                                 removeImageView={() => {
                                   form.change('imageView', undefined);
                                   form.change('image', undefined);
                                 }
                                 }
                                 getErrorCode={getFineInvalidTypeError}
                          />
                        </Grid.Column>
                      </Grid.Row>

                    </InnerTsaGrid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}
                         style={{ borderTop: '1px solid #d4d9e0', marginTop: '2rem' }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={onBackButtonClick}
                style={{ marginRight: 0 }}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('clinic.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};

export default UpsertClinicForm;