import { useAuthContext } from 'auth/AuthContext';
import CompositeButton from 'components/final-form/CompositeButton';
import FinalFormVerificationInput from 'components/final-form/VerificationInput';
import StyledErrorMessage from 'components/StyledErrorMessage';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { errorUtils } from 'util/errorUtils';

const ContainerDiv = styled.div`

  .auth-form {
    display: grid;
    grid-template-columns: 150px minmax(10rem, 25rem);

    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    margin-top: 2.5rem;

    label {
      align-self: center;
      width: 20rem;
    }

    .btn-span {
      display: inline-flex;
      margin-left: 12.5rem;
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;

      a.text-link {
        color: darkred;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      &.text {
        margin-top: 0;
      }
    }

    .error-message {
      margin-bottom: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      position: relative;
    }
  }

  @media only screen and (max-width: 767px) {
    .auth-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }

      .action-button {
        display: block;
        width: 100%;
      }
    }
  }
`;

interface Props {
  username: string,
  password: string,
  onCancel: () => void
}

const TwoFactorAuth: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation('login');
  const { login } = useAuthContext();
  const history = useHistory();

  const { username, password, onCancel } = props;

  const [error, setError] = useState<string | undefined>(undefined);

  const handleError = useCallback((error: any) => {

    if (error
      && error.response.data.error
      && error.response.data.error === errorUtils.invalidVerificationCode) {

      setError(t('error.INVALID_VERIFICATION_CODE'));
      return;
    }

    setError('error.general');
  }, []);

  const handleSubmit = useCallback(async (values: any) => {

    const verificationCode = values.verificationCode;
    if (_.isEmpty(verificationCode)) {
      return;
    }

    try {
      await login(username, password, verificationCode);
      history.push('/');
    } catch (e) {
      handleError(e);
    }
  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: any) => handleSubmit(values)}
        subscription={{ pristine: true, submitting: true }}
        initialValues={{ verificationCode: undefined }}
        render={(formProps: FormRenderProps) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting }: FormRenderProps): React.ReactNode => {
    return <form onSubmit={handleSubmit}>
      <div className='title-h1'>{t('login.2fa.title')}</div>
      <div className='auth-form'>
        {
          error &&
          <div className='error-message'>
            <StyledErrorMessage onDismiss={() => setError(undefined)}>
              <div key={error}>{error}</div>
            </StyledErrorMessage>
          </div>
        }
        <label>{t('login.strongAuthentication')}</label>
        <Field
          name='verificationCode'
          component={FinalFormVerificationInput}
        />
        <span className='btn-span'>
        <CompositeButton
          type='submit'
          className='action-button'
          style={{ marginRight: '1rem' }}
          primary
          disabled={submitting}
        >
          {t('action.login')}
        </CompositeButton>
        <CompositeButton
          type='button'
          className='action-button'
          style={{ whiteSpace: 'nowrap' }}
          secondary
          onClick={onCancel}
          disabled={submitting}
        >
          {t('action.backToLogin')}
        </CompositeButton>
      </span>
      </div>
    </form>;
  };

  return (
    <ContainerDiv>
      {renderFinalForm()}
    </ContainerDiv>
  );
};

export default TwoFactorAuth;
