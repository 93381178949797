import axios from 'axios';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import React, { useCallback, useState } from 'react';
import { getClinicInfo } from 'service/clinicService';
import { ClinicInfoDto } from 'ts-types/api.types';

interface State {
  clinicId?: number;
  clinicInfo?: ClinicInfoDto;
  setClinicId: (clinicId: number | undefined) => void;
}

const ClinicInfoContext = React.createContext<State>({
  clinicInfo: undefined,
  clinicId: undefined,
  setClinicId: (clinicId: number | undefined) => {},
});

const cancelTokenSource = axios.CancelToken.source();

interface Props extends JSX.ElementChildrenAttribute {}

const ClinicInfoProvider = (props: Props) => {

  const [clinicInfo, setClinicInfo] = useState<ClinicInfoDto | undefined>(undefined);
  const [clinicId, setClinicId] = useState<number | undefined>(undefined);

  const {setClinicId: setHeaderClinicId} = useClinicHeaderContext();

  const fetchUserClinicInfo = useCallback(async () => {

    if (!clinicId) {
      setClinicInfo(undefined);
      return;
    }

    try {
      const clinicInfo: ClinicInfoDto = await getClinicInfo(clinicId, cancelTokenSource);
      setClinicInfo(clinicInfo);
    } catch (e) {}
  }, [clinicInfo, setClinicInfo, clinicId]);

  React.useEffect(() => {
    setHeaderClinicId(clinicId);
  }, [clinicId]);


  React.useEffect(() => {
    fetchUserClinicInfo();
  }, [clinicId]);

  const state: State = React.useMemo(() => {
    return { clinicInfo, setClinicId };
  }, [clinicInfo, setClinicId]);

  return (
    <ClinicInfoContext.Provider value={state}>
      {props.children}
    </ClinicInfoContext.Provider>
  );
};

export const useClinicInfoContext = () => React.useContext(ClinicInfoContext);

export default ClinicInfoProvider;