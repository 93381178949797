import { CancelTokenSource } from 'axios';
import { ClinicDto, ClinicInfoDto, UpsertClinicDto } from 'ts-types/api.types';
import axios, { backendUrls } from 'service/http';

const apiUri = `${backendUrls.apiRoot}/clinic`;

export const getClinicData = (source: CancelTokenSource): Promise<ClinicDto> => {
  return axios
  .get(`${apiUri}/data`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getClinicInfo = (clinicId: number ,source: CancelTokenSource): Promise<ClinicInfoDto> => {
  return axios
    .get(`${apiUri}/info/${clinicId}`, { cancelToken: source.token })
    .then(response => response.data);
};

export const getClinicAdminClinicDetails = (source: CancelTokenSource): Promise<ClinicDto> => {
  return axios
  .get(`${apiUri}/details`, { cancelToken: source.token })
  .then(response => response.data);
};

export const findAdminClinic = (source: CancelTokenSource): Promise<UpsertClinicDto> => {
  return axios
  .get(`${apiUri}/current`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addClinic = (upsertClinicDto: Partial<UpsertClinicDto>, source: CancelTokenSource): Promise<ClinicDto> => {

  let bodyFormData = new FormData();
  Object.keys(upsertClinicDto).forEach((key) => {
    let value = upsertClinicDto[key as keyof UpsertClinicDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .post(apiUri, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const editClinic = (upsertClinicDto: Partial<UpsertClinicDto>, source: CancelTokenSource): Promise<ClinicDto> => {

  let bodyFormData = new FormData();
  Object.keys(upsertClinicDto).forEach((key) => {
    let value = upsertClinicDto[key as keyof UpsertClinicDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .put(apiUri, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteClinic = (clinicId: number, source: CancelTokenSource): Promise<ClinicInfoDto> => {

  return axios
  .delete(`${apiUri}/${clinicId}`, { cancelToken: source.token })
  .then(response => response.data);
};
