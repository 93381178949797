import axios from 'axios';
import _ from 'lodash';
import React, { useState } from 'react';
import { getAvailableExerciseResultParams } from 'service/teresaConfService';
import { getExercising } from 'service/patientExercisingService';
import { getPatientDetails } from 'service/patientService';
import { ExercisingRequest, PatientDto, TestResultParamDto, UpsertExercisingDto } from 'ts-types/api.types';

interface State {
  patientExerciseFormData: Partial<UpsertExercisingDto> | undefined;
  setPatientExerciseFormData: (values: Partial<UpsertExercisingDto> | undefined) => void;
  patientData: PatientDto | undefined;
  exerciseResultParamMap: { [key: number]: TestResultParamDto };
  clearPatientExerciseFormData: () => void;
  initializePatientExerciseForm: (request: Partial<ExercisingRequest>) => void;
  formDataLoaded: boolean;
}

const PatientExerciseFormDataContext = React.createContext<State>({
  patientExerciseFormData: undefined,
  setPatientExerciseFormData: (values: Partial<UpsertExercisingDto> | undefined) => {},
  patientData: undefined,
  exerciseResultParamMap: {},
  clearPatientExerciseFormData: () => {},
  initializePatientExerciseForm: (request: Partial<ExercisingRequest>) => {},
  formDataLoaded: false,
});

interface Props extends JSX.ElementChildrenAttribute {}

const cancelTokenSource = axios.CancelToken.source();
const PatientExerciseFormDataProvider = (props: Props) => {

  const [patientExerciseFormData, setPatientExerciseFormData] =
    useState<Partial<UpsertExercisingDto> | undefined>(undefined);

  const [patientData, setPatientData] = React.useState<PatientDto | undefined>(undefined);

  const [exerciseResultParamMap, setExerciseResultParamMap] =
    useState<{ [key: number]: TestResultParamDto }>([]);

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);

  const clearPatientExerciseFormData = () => {
    setPatientExerciseFormData(undefined);
    setPatientData(undefined);
    setExerciseResultParamMap([]);
  };

  const initializePatientExerciseForm = async (request: Partial<ExercisingRequest>) => {

    setFormDataLoaded(false);

    try {
      if (request) {

        const upsertExercisingDto = await getExercising(request, cancelTokenSource);
        setPatientExerciseFormData(upsertExercisingDto);

        const patientDataResponse = await getPatientDetails(upsertExercisingDto.patientId, cancelTokenSource);
        setPatientData(patientDataResponse);

        const allExerciseResultParams = await getAvailableExerciseResultParams(cancelTokenSource);
        setExerciseResultParamMap(_.keyBy(allExerciseResultParams, 'id'));
      }
    } finally {
      setFormDataLoaded(true);
    }
  };

  const state: State = React.useMemo(() => {
      return {
        patientExerciseFormData, setPatientExerciseFormData,
        patientData, exerciseResultParamMap,
        clearPatientExerciseFormData, initializePatientExerciseForm,
        formDataLoaded,
      };
    }, [
      patientExerciseFormData, setPatientExerciseFormData,
      patientData, exerciseResultParamMap,
      clearPatientExerciseFormData, initializePatientExerciseForm,
      formDataLoaded,
    ],
  );

  return (
    <PatientExerciseFormDataContext.Provider value={state}>
      {props.children}
    </PatientExerciseFormDataContext.Provider>

  );
};

export const usePatientExerciseFormDataContext = () => React.useContext(PatientExerciseFormDataContext);

export default PatientExerciseFormDataProvider;