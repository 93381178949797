import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import Input from 'components/final-form/Input';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Loader } from 'semantic-ui-react';
import { searchClinics } from 'service/adminService';
import { deleteClinic } from 'service/clinicService';
import axios from 'service/http';
import { debounce } from 'ts-debounce';
import { ClinicDto, ClinicSearchRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { defaultContentCellRenderer, shortLabel } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

const ClinicsView = () => {

  const { t } = useTranslation('teresa');
  const history = useHistory();

  const [foundClinics, setFoundClinics] = useState<ClinicDto[]>([]);
  const [clinicsLoaded, setClinicsLoaded] = useState<boolean>(true);

  const [searchValues, setSearchValues] = useState<Partial<ClinicSearchRequest>>({
    searchKey: '',
  });

  const {setClinicId} = useClinicInfoContext();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] =
    useState<string | undefined>(undefined);

  React.useEffect(() => {
    fetchClinics(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.userNotAdmin,
        errorUtils.clinicNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchClinics(values);
    setClinicId(undefined);
  });

  const fetchClinics = debounce((values: Partial<ClinicSearchRequest>): void => {

    const onFinally = () => {
      setClinicsLoaded(true);
    };

    setClinicsLoaded(false);
    setFoundClinics([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    searchClinics(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundClinics(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const openClinicDetails = (id: number): void => {
    setClinicId(id);
    history.push('/clinic/details', {
      id: id,
      clinicId: id
    });
  };

  const openClinicInvoice = (id: number): void => {
    setClinicId(id);
    history.push('/clinic/invoice', {
      clinicId: id,
    });
  };

  const openClinicConfig = (id: number): void => {
    setClinicId(id);
    history.push('/clinic/config', {
      clinicId: id,
      prevPath: history.location.pathname,
    });
  };

  const openClinicStats = (id: number): void => {
    setClinicId(id);
    history.push('/clinic/stats', {
      clinicId: id,
      prevPath: history.location.pathname,
    });
  };

  const removeClinic = (id: number, values: any) => {
    deleteClinic(id, cancelTokenSource)
    .then(() => {
      setSuccessMessage(t("clinic.successfullyDeleted.message"));
      fetchClinics(values);
    })
    .catch((e: any) => handleError(e.response.data));
  };

  const renderClinicsTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundClinics.length}
        rowGetter={clinicsRowGetter}
        rowRenderer={clinicsRowRenderer}
        columns={[
          {
            width: 300,
            flexGrow: 1,
            label: t('clinic.name'),
            dataKey: 'name',
          },
          {
            width: 130,
            flexGrow: 1,
            label: t('clinic.mandate'),
            dataKey: 'mandateName',
            cellRenderer: defaultContentCellRenderer,
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.doctorNumber')),(t('clinic.doctorNumberShort'))),
            dataKey: 'doctorNumber',
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.patientNumber')),(t('clinic.shortPatientNumber'))),
            dataKey: 'patientNumber',
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.availableTestNumber')),(t('clinic.shortAvailableTestNumber'))),
            dataKey: 'availableTests',
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.availableTestTemplatesNumber')),(t('clinic.shortAvailableTestTemplatesNumber'))),
            dataKey: 'availableTestTemplates',
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.availableTestQuestionsNumber')),(t('clinic.shortAvailableTestQuestionsNumber'))),
            dataKey: 'availableTestQuestions',
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.availableExercisesNumber')),(t('clinic.shortAvailableExercisesNumber'))),
            dataKey: 'availableExercises',
          },
          {
            width: 50,
            label: shortLabel(( t('clinic.availableExerciseTemplatesNumber')),(t('clinic.shortAvailableExerciseTemplatesNumber'))),
            dataKey: 'availableExerciseTemplates',
          },
          {
            width: 40,
            label: shortLabel(( t('clinic.availableExerciseQuestionsNumber')),(t('clinic.shortAvailableExerciseQuestionsNumber'))),
            dataKey: 'availableExerciseQuestions',
          },
          {
            width: 390,
            flexGrow: 1,
            flexShrink: 0,
            label: t('clinic.actions'),
            dataKey: 'id',
            cellRenderer: (actionsCellRenderer(values))
          }
        ]}
      />

    );
  };

  const renderSearchClinicForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('clinic.administration')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('clinic.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push('/clinic')}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>

          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        {successMessage != undefined &&
          <StyledErrorMessage
            onDismiss={() => setSuccessMessage(undefined)}
            color='green'
          >
            <div>{successMessage}</div>
          </StyledErrorMessage>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {clinicsLoaded && renderClinicsTable(values)}
          {!clinicsLoaded &&
            <Loader className='table-loader' active inline content={t('clinic.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const clinicsRowGetter = ({ index }: any) => {

    Object.assign(foundClinics[index], { index: index + 1 });

    return foundClinics[index];
  };

  const clinicsRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const clinicId = foundClinics[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openClinicDetails(clinicId!)}
      >
        {columns}
      </div>
    );
  };

  const actionsCellRenderer = (values: any) => ({ rowData }: any) => {

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('clinic.details.open')}
          onConfirm={() => openClinicDetails(rowData.id)}
          divider={true}
        />
        <InnerTableActionButton
          message={t('clinic.config.open')}
          onConfirm={() => openClinicConfig(rowData.id)}
          divider={true}
        />
        <InnerTableActionButton
          message={t('clinic.actionStats')}
          onConfirm={() => openClinicStats(rowData.id)}
          divider={true}
        />
        <InnerTableActionButton
          message={t('clinic.invoice')}
          onConfirm={() => openClinicInvoice(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation
          triggerButtonText={t('button.delete')}
          confirmAction={() => removeClinic(rowData.id, values)}
          deleteConfirmationText={t('clinic.confirmDelete', {clinicName: rowData.name})}
          position={'top left'}
        />
      </div>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderSearchClinicForm()}
    </SearchDisplayContainer>
  );
};

export default ClinicsView;