import axios from 'axios';
import { CheckboxSc } from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import DropZone from 'components/final-form/DropZone';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import ViewContentWrapperDiv from 'components/ViewContentWrapperDiv';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { FormApi } from 'final-form';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import { importDoctors, uploadDoctorsFile } from 'service/doctorImportService';
import {
  DoctorImportDto,
  DoctorImportRequest,
  UpsertDoctorImportDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { emptyTableCell } from 'util/tableUtils';

interface Props {

}

const PopupStyle = {
  borderRadius: 'unset',
  padding: '10px',
};

const ImportPage = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const { clinicInfo, setClinicId } = useClinicInfoContext();

  const clinicId: number | undefined = state.clinicId ? Number(state.clinicId) : undefined;

  const [foundDoctors, setFoundDoctors] = useState<DoctorImportDto[]>([]);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
  }, [])

  const handleError = useCallback((error: any) => {
    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }

  }, []);


  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const goToPreviousPage = () => {
    push('/clinic/details', {
      id: clinicId,
      clinicId: state.clinicId
    });
  };

  const importDoctorsFromFile = async () => {
    const importRequest: Partial<DoctorImportRequest> = {
      doctors: foundDoctors,
    };

    try {
      const response = await importDoctors(
        importRequest,
        clinicId,
        cancelTokenSource
      );
      setSuccessMsg(t('import.addSuccess'));
      setTimeout(() => {
        setSuccessMsg(undefined);
        goToPreviousPage();
      }, 1200);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    setClinicId(clinicId);
    return (
      <FinalForm
        onSubmit={() => importDoctorsFromFile()}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps) => renderFormContent(formProps)}
      />
    );
  };


  const doctorsRowGetter = ({ index }: any) => {
    Object.assign(foundDoctors[index], { index: index + 1 });

    return foundDoctors[index];
  };

  const doctorsRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };
    const rowData = foundDoctors[index];
    const disabled = rowData.exists || rowData.invalid;

    let classNames = className;
    if (disabled) {
     classNames += ' disabled'
    }

    return (
      <div
        {...a11yProps}
        className={classNames}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const cellRenderer = ({ cellData }: any) => {
    return (
      <>
        {cellData ? <div>{cellData}</div> : emptyTableCell()}
      </>
    );
  };

  const handleFileUpload = (request: Partial<UpsertDoctorImportDto>) => {

    uploadDoctorsFile(request, clinicId, cancelTokenSource)
    .then(response => {
        setFoundDoctors(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(() => setFormDataLoaded(true));
  };


  const onDropAccepted =  (acceptedFiles: File[], form: FormApi) => {
    form.change("file", acceptedFiles[0]);
  };

  const renderFormContent = ({ handleSubmit, submitting, form, values }: FormRenderProps): React.ReactNode => {
    const uploadMessage = values.file ? values.file.name : t("import.uploadFile");
    const countSelectedDoctors = foundDoctors.filter(e => e.selected).length;

    const clinicName = clinicInfo ? `(${clinicInfo.name})` : "";

    return (
      <>
        <form onSubmit={handleSubmit}>
          <TsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='title-h1'>{t('import.viewTitle')} {clinicName}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <Grid>
                  {
                    errorMessages.length > 0 &&
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <div className='error'>
                          <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                            {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                          </StyledErrorMessage>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  }

                  <DataLabel>{t('import.uploadFile')}</DataLabel>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <DropZone
                        multiple={false}
                        message={uploadMessage}
                        onDropAccepted={(acceptedFiles: File[]) => onDropAccepted(acceptedFiles, form)}
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <CompositeButton
                        type='button'
                        primary
                        className='action-button'
                        size='small'
                        onClick={() => handleFileUpload(values)}
                        disabled={!values.file}
                      >
                        {t('button.upload')}
                      </CompositeButton>

                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={16}>
                      <div className='title-h1'>
                        {t('import.tableHeader')} ( {countSelectedDoctors} / {(foundDoctors.length)} )
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </form>

        <div className='data-table'>
          {renderDoctorsTable()}
        </div>

        <div style={{ textAlign: "right" }}>
          <Grid.Row>
            <Grid.Column width={16} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <div className="page-actions">
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting || !!successMsg || isEmpty(foundDoctors) || (countSelectedDoctors == 0)}
                  onClick={importDoctorsFromFile}
                >
                  {t('button.import')}
                </CompositeButton>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  disabled={submitting || !!successMsg}
                  onClick={goToPreviousPage}
                >
                  {t('action.back')}
                </CompositeButton>
              </div>
            </Grid.Column>
          </Grid.Row>
        </div>
      </>
    );
  };

  const selectAllCheckBox = () => {
    const countSelectableDoctors = foundDoctors.filter(e => !e.invalid).length;
    const countSelectedDoctors = foundDoctors.filter(e => e.selected).length;
    const indeterminate = countSelectableDoctors > 0 && countSelectedDoctors === 1;
    const noSelectedDoctors = countSelectedDoctors === 0 && countSelectableDoctors > 0;
    const allDoctorsSelected = countSelectableDoctors > 0 && countSelectedDoctors === countSelectableDoctors;

    return (
      <>
        <div>
          <CheckboxSc
            checked={countSelectedDoctors > 0}
            indeterminate={indeterminate}
            onChange={
              () => {
                if (noSelectedDoctors || indeterminate) {
                  let doctors = [...foundDoctors];
                  const selectedDoctors = doctors.map(e => {
                    if (!e.invalid) {
                      return {...e, selected: true};
                    }
                    return e;
                  });
                  setFoundDoctors(selectedDoctors);
                }

                if (allDoctorsSelected) {
                  let doctors = [...foundDoctors];
                  const selectedDoctors = doctors.map(e => ({...e, selected: false}));
                  setFoundDoctors(selectedDoctors);
                }

              }
            }
            disabled={countSelectableDoctors === 0}
          />
        </div>
      </>
    )
  };

  const selectDoctors = (index: number) => {

    let doctor = foundDoctors[index];
    if (doctor && !doctor.invalid) {
      let doctors = [...foundDoctors];
      doctors[index].selected = !doctors[index].selected;
      setFoundDoctors(doctors);
    }
  };

  const singleRecordCheckBox = ({ rowData, rowIndex }: any) => {
    const checkedElement: boolean = rowData.selected;
    const isDisabled = rowData.invalid || rowData.exists;
    const errorList: string[] = [rowData.importErrors];

    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          {rowData.importErrors && (
            <Popup
              trigger={
                <Icon
                  className="exclamation triangle icon"
                  style={{ color: "red", margin: "1px" }}
                  size="large"
                />
              }
              content={
                <>
                  {errorList.map((error, index) => (
                    <div key={index} style={{ marginLeft: "5px" }}>
                      {t(`error.${error}`, { index: rowIndex + 1 })}
                    </div>
                  ))}
                </>
              }
              size="small"
              position="top center"
              on="hover"
              style={PopupStyle}
            />
          )}

          {
            !isDisabled ?
              <CheckboxSc
                disabled={isDisabled}
                checked={checkedElement}
                onChange={() => selectDoctors(rowIndex)}
                style={{ margin: "1.5px" }}
              /> : ""
          }
        </div>
      </>
    );
  };


  const renderDoctorsTable = (): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundDoctors.length}
        rowGetter={doctorsRowGetter}
        rowRenderer={doctorsRowRenderer}
        headerHeight={35}
        columns={[
          {
            width: 100,
            label: selectAllCheckBox(),
            dataKey: 'patientTestId',
            cellRenderer: singleRecordCheckBox,
          },
          {
            width: 500,
            label: t('doctor.firstName'),
            dataKey: 'name',
            cellRenderer: (cellRenderer),
          },
          {
            width: 500,
            label: t('doctor.lastName'),
            dataKey: 'firstName',
            cellRenderer: (cellRenderer),
          },
          {
            width: 500,
            label: t('doctor.email'),
            dataKey: 'email',
            cellRenderer: (cellRenderer),
          },
          {
            width: 300,
            label: t('doctor.tel'),
            dataKey: 'tel',
            cellRenderer: (cellRenderer),
          },
          {
            width: 500,
            label: t('doctor.street'),
            dataKey: 'street',
            cellRenderer: (cellRenderer),
          },
          {
            width: 200,
            label: t('doctor.zip'),
            dataKey: 'zip',
            cellRenderer: (cellRenderer),
          },
          {
            width: 100,
            label: t('doctor.language'),
            dataKey: 'language',
          },
        ]}
      />
    );
  };


  return (
    <ViewContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('import.loading')} />
      }
    </ViewContentWrapperDiv>
  );

};
export default ImportPage;