import DataLabel from 'components/final-form/DataLabel';
import CompositeButton from 'components/final-form/CompositeButton';
import MainContainerComponent from 'components/MainContainerComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Header, Input, InputOnChangeData } from 'semantic-ui-react';
import axios from 'service/http';
import { resetPassword, SetPasswordDto, validateToken } from 'service/passwordResetServices';
import styled from 'styled-components';
import { errorUtils } from 'util/errorUtils';
import { isKeyCheck } from 'util/keyUtils';

const ContainerDiv = styled.div`

   .container-div {
    justify-content: center;
    background-color: white;
    padding: 50px;
    max-width: 650px;
    border: 1px solid var(--very-very-light-gray);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   }

   .header-div {
    text-align: center;
   }

  .email-form {
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;

    margin-top: 2rem;
    
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    label {
      align-self: center;
    }

    .btn-span {
      display: inline-flex;
    }

    .error-message {
      margin-bottom: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      position: relative;
    }

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      &.text {
        margin-top: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .email-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }
      
      .btn-span {
        margin-left: unset;
      }

      .action-button {
        display: block;
        width: 100%;
      }
    }
  }
`;

const cancelTokenSource = axios.CancelToken.source();

const ResetPassword = () => {

  const { t } = useTranslation('login');
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location.search);
  const token = queryParams.get('token');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [fieldErrorPassword, setFieldErrorPassword] = useState<boolean>(false);
  const [fieldErrorConfirmPassword, setFieldErrorConfirmPassword] = useState<boolean>(false);

  const goToLogin = () => {
    history.push('/login');
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.passwordsDoNotMatch,
        errorUtils.passwordResetTokenInvalid,
        errorUtils.passwordResetTokenExpired
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const passwordDoNotMatchError = () => {
    setErrorMessage(t('error.PASSWORDS_DO_NOT_MATCH'));
  };

  const passwordFieldLengthError = () => {
    setErrorMessage(t('error.PASSWORD_FIELD_LENGTH_DO_NOT_MATCH'));
  };

  const emptyPasswordField = () => {
    setErrorMessage(t('error.EMPTY_PASSWORD_FIELD'));
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const setInputPassword = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {

    if (password !== confirmPassword) {
      setFieldErrorPassword(false);
    }
    setPassword(data.value);
  };

  const setInputConfirmPassword = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {

    if (password !== confirmPassword) {
      setFieldErrorConfirmPassword(false);
    }
    setConfirmPassword(data.value);

  };

  const tokenValidation = () => {
    if (token) {
      try {
        const response = validateToken(token, cancelTokenSource);
      } catch (e) {}
    }
  };

  React.useEffect(() => {
    tokenValidation();
  }, []);


  const passwordReset = async () => {

    if (password === '' || confirmPassword === '') {
      setFieldErrorPassword(true);
      setFieldErrorConfirmPassword(true);
      emptyPasswordField();
      return;

    }

    if (password !== confirmPassword) {
      setFieldErrorPassword(true);
      setFieldErrorConfirmPassword(true);
      passwordDoNotMatchError();
      return;
    }

    if (password.length < 8) {
      setFieldErrorPassword(true);
      setFieldErrorConfirmPassword(true);
      passwordFieldLengthError();
      return;
    }

    const setPasswordDto: SetPasswordDto = {
      password: password!,
    };


    try {
      const response = await resetPassword(token!, setPasswordDto, cancelTokenSource);
      goToLogin();
    } catch (e: any) {
      handleError(e.response.data);
    }
  };

  const onEnterKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      passwordReset();
    }
  };


  const renderFormContent = (): React.ReactNode => {
    return <MainContainerComponent>
      <ContainerDiv>
        <div className={'container-div'}>
          <div className={'header-div'}>
            <Header as='h3'>{t('login.resetPasswordTitle')}</Header>
          </div>
          <div className='email-form'>

            {
              errorMessages.length > 0 &&
              <div className='error-message'>
                <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                  {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                </StyledErrorMessage>
              </div>
            }

            <Grid.Row>
              <Grid.Column width={4}>
                <DataLabel>{t('login.password')}</DataLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <Input
                  placeholder={t('login.placeHolder.password')}
                  type='password'
                  value={password}
                  error={fieldErrorPassword}
                  onChange={setInputPassword}
                  onKeyDown={(e: any) => onEnterKeyDown(e)}
                  autoFocus
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <DataLabel>{t('login.confirmPassword')}</DataLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <Input
                  placeholder={t('login.placeHolder.password')}
                  type='password'
                  value={confirmPassword}
                  error={fieldErrorConfirmPassword}
                  onChange={setInputConfirmPassword}
                  onKeyDown={(e: any) => onEnterKeyDown(e)}
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            <div></div>
            <span className='btn-span'>
        <CompositeButton
          type='button'
          className='action-button'
          style={{ marginRight: '1rem' }}
          primary
          onClick={passwordReset}
        >
          {t('action.confirm')}
        </CompositeButton>
        <CompositeButton
          type='button'
          className='action-button'
          style={{ whiteSpace: 'nowrap' }}
          secondary
          onClick={goToLogin}
        >
          {t('action.backToLogin')}
        </CompositeButton>
      </span>
          </div>
        </div>
      </ContainerDiv>
    </MainContainerComponent>;
  };

  return <>
    {renderFormContent()}
  </>;
};

export default ResetPassword;
