import { StyledTabs } from 'components/final-form/TabComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigView } from 'routes/clinic/ClinicConfigView';

interface Props {
  activeView: ConfigView;
  onTabChange: (view: ConfigView) => void;
}

const ClinicConfigTabComponent = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { activeView, onTabChange } = props;

  const tabLabels: string[] = Object.values(ConfigView);
  const tabs = tabLabels.map((tabName: string, index: number) => {
    return <div key={tabName}
                className={`tab ${activeView === tabName && 'selected'}`}
                onClick={() => onTabChange(tabName as ConfigView)}
    >
      {t(`tab.${tabName}`)}
    </div>;
  });

  return (
    <StyledTabs>
      {tabs}
    </StyledTabs>
  );
};

export default ClinicConfigTabComponent;
