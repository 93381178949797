import { AxiosError } from 'axios';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import MainContainerComponent from 'components/MainContainerComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import { activateAccount } from 'service/accountService';
import axios from 'service/http';
import styled from 'styled-components';
import { InvalidInputDto, ObjectValidationErrorDto, UpsertAccountDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { required } from 'util/validatorUtils';

const GridColumn = styled(Grid.Column)`
  padding-right: 0 !important;
`

const ContainerDiv = styled.div`
   
   .container-div {
    justify-content: center;
    background-color: white;
    padding: 50px;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   }
   
   .header-div {
    text-align: center;
   }
    
  .email-form {
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;

    margin-top: 2rem;
    
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;


    label {
      align-self: center;
    }

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      &.text {
        margin-top: 0;
      }
    }

    .error-message {
      margin-bottom: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      position: relative;
    }
  }

  @media only screen and (max-width: 767px) {
    .email-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }

      .action-button {
        display: block;
        width: 100%;   
      }
    }
  }
`;

interface ExtendedUpsertAccountDto extends UpsertAccountDto {
  confirm?: string;
}

interface Props {
  accountData?: Partial<UpsertAccountDto>;
}

const ActivateAccountForm: React.FC<Props> = (props: Props) => {

    const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);

    const { t } = useTranslation('login');
    const { push, goBack } = useHistory();
    const { accountData } = props;

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleError = (error: AxiosError<InvalidInputDto>) => {

    const { response } = error;
    if (response) {
      const { data: { errorCode, violations } } = response;

      const knownErrors: Array<string> = [
        errorUtils.userNotAdmin,
        errorUtils.emailAlreadyInuse,
        errorUtils.usernameAlreadyExists,
        errorUtils.passwordSize,
        errorUtils.invalidEmail
      ];

      if (violations && violations.length > 0) {
        violations.forEach((violation: ObjectValidationErrorDto) => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const [activationInProgress, setActivationInProgress] = React.useState<boolean>(false);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);

  const handleSubmit = useCallback(async (values: Partial<ExtendedUpsertAccountDto>) => {

    const onSave = () => {
      setSuccessMsg(t('account.activationSuccess'));
    };

    if (values.password !== values.confirm) {
      setErrorMessage(t('error.confirmPassword'));
    } else {
      setActivationInProgress(true);

      const saveValues: Partial<UpsertAccountDto> = { ...values };

     await activateAccount(saveValues, cancelTokenSource)
      .then(onSave)
      .catch(handleError)
      .finally(() => {
        setActivationInProgress(false);
      });

    }

  }, []);

  useEffect(() => {
    if (successMsg) {
      push('/login', { successMessage: successMsg });
    }
  }, [successMsg]);

    const renderFormContent = useCallback((
      { handleSubmit, submitting, values }: FormRenderProps<UpsertAccountDto>): React.ReactNode => {

      return (
        <form onSubmit={handleSubmit}>
          <TsaGrid>
            <Grid.Row>
              <GridColumn width={16}>
                <TsaGrid>
                  {
                    errorMessages.length > 0 &&
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <div className='error'>
                          <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                            {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                          </StyledErrorMessage>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  }

                  <Grid.Row textAlign='center'>
                    <Grid.Column width={16}>
                      <Header as='h4'>{t('account.userName')} {values.name} {values.lastName}.</Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <GridColumn width={16} verticalAlign='middle'>
                      <DataLabel>{t('account.regKey')}</DataLabel>
                      <Field
                        fluid
                        name='regKey'
                        component={Input}
                        validate={required}
                        disabled
                      />
                    </GridColumn>
                  </Grid.Row>

                  <Grid.Row>
                    <GridColumn width={16} verticalAlign='middle'>
                      <DataLabel>{t('account.username')}</DataLabel>
                      <Field
                        fluid
                        name='username'
                        component={Input}
                        validate={required}
                      />
                    </GridColumn>
                  </Grid.Row>

                  <Grid.Row>
                    <GridColumn width={16} verticalAlign='middle'>
                      <DataLabel>{t('account.password')}</DataLabel>
                      <Field
                        fluid
                        name='password'
                        component={Input}
                        type='password'
                        validate={required}
                      />
                    </GridColumn>
                  </Grid.Row>

                  <Grid.Row>
                    <GridColumn width={16} verticalAlign='middle'>
                      <DataLabel>{t('account.confirmPassword')}</DataLabel>
                      <Field
                        fluid
                        name='confirm'
                        component={Input}
                        type='password'
                        validate={required}
                      />
                    </GridColumn>
                  </Grid.Row>
                </TsaGrid>
              </GridColumn>
            </Grid.Row>

            <Grid.Row>
              <GridColumn width={8} textAlign={'right'}>
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={noop}
                >
                  {t('account.activate')}
                </CompositeButton>
              </GridColumn>
              <GridColumn width={8} textAlign={'left'}>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  disabled={submitting}
                  onClick={() => { push('/login');}}
                  style={{ marginLeft: '1rem' }}
                >
                  {t('action.back')}
                </CompositeButton>
              </GridColumn>
            </Grid.Row>
          </TsaGrid>
        </form>
      );
    }, [successMsg, errorMessages]);

  const finalForm = React.useMemo((): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertAccountDto>) => handleSubmit(values)}
        initialValues={accountData}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertAccountDto>) => renderFormContent(formProps)}
      />
    );
  }, [accountData, renderFormContent]);

    return (
      <MainContainerComponent>
        <ContainerDiv>
          <div className={'container-div'}>
            <div className={'header-div'}>
              <div className='title-h1'>{t('account.viewTitle')}</div>
            </div>

          {!activationInProgress
            ? <React.Fragment>
              {finalForm}
            </React.Fragment>
            : <LoaderComponent message={t('account.loading')} />
          }
          </div>
        </ContainerDiv>
      </MainContainerComponent>
    );
  }
;

export default ActivateAccountForm;