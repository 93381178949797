import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const TableContainerStyle = styled.div<{ height: number }>`
  flex-grow: 1;
  cursor: context-menu;
  border-radius: unset;

  &.table-container {
    .ui.table {
      border-spacing: 0 2px !important;
    }

    .ui.table tbody {
      display: block;
      height: ${(props) => props.height}px;
      overflow: auto;
    }

    .ui.table thead,
    .ui.table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .ui.basic.table tbody tr {
      border-bottom: unset;
    }

    .ui.table thead th {
      color: var(--light-blue);
      text-transform: uppercase !important;
      border-bottom: none;
      font-size: 10px;
      padding-bottom: 0;
      padding-left: 3px;
    }

    .ui.table thead th:first-of-type {
      padding-left: 5px !important;
    }

    & .ui.table tr {
      height: 34px;
    }

    .ui.table tr td {
      color: var(--dark-gray);
      background-color: white !important;
      padding: 5px 3px;
      border-color: var(--very-very-light-blue) !important;

      .ui.tiny.primary.button + .ui.tiny.primary.button {
        margin-left: 5px;
      }

      .ui.checkbox {
        margin: 0;
        min-height: 13px;
        vertical-align: center;
      }

      @media only screen and (max-width: 1474px) {
        .ui.tiny.primary.button + .ui.tiny.primary.button {
          margin-left: 0;
        }
      }
    }

    .ui.table tr:hover td {
      background: rgba(0, 0, 0, .05) !important;
      color: rgba(0, 0, 0, .95) !important;
    }

    .action-buttons {
      display: flex;
    }

    .ui.basic.table tbody tr td:first-of-type {
      padding-left: 5px !important;
      border-left: 1px solid var(--very-very-light-blue);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .ui.basic.table tbody tr td:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid var(--very-very-light-blue);
    }
  }

  &.small-table-container {
    .ui.table thead th {
      color: var(--light-blue);
      text-transform: uppercase !important;
      border-bottom: none;
      font-size: 10px;
      padding-bottom: 0;
      padding-left: 3px;
    }

    .ui.table tr td {
      color: var(--dark-gray);
      background-color: white !important;
      padding: 5px 3px;
      border-color: var(--very-very-light-blue) !important;
    }
  }
`;

interface Props extends DividerProps {
  height?: number;
}

const TableContainer = (props: Props) => {

  const isIpad = useIsIpadWidthOrBelow(767);

  const { className, children, height = 144, ...rest } = props;

  let classNames = isIpad ? 'small-table-container' : 'table-container';
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <TableContainerStyle
      className={classNames}
      height={height} {...rest}
    >
      {children}
    </TableContainerStyle>
  );
};

export default TableContainer;
