import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  ClinicConfDetailsDto,
  DisplayTestImageDto,
  ExerciseConfDto,
  ExerciseResultDto,
  ExerciseTemplateDto,
  FormulaTestDto,
  InvoiceConfigDto,
  QuestionSetChartDto,
  QuestionSetDto,
  QuestionSetsPreview,
  ScoringTestDto,
  ScoringTestResponse,
  TeresaConfQuestionSetSearchRequest,
  TestConfDto,
  TestResultDto,
  TestResultParamDto,
  TestTemplateDto,
  UpsertExerciseTemplateDto,
  UpsertQuestionSetDto,
  UpsertQuestionSetRequest,
  UpsertTestConfDto,
  UpsertTestTemplateDto,
} from 'ts-types/api.types';


const apiUri = `${backendUrls.apiRoot}/teresa-conf`;
const testConfApiUri = `${apiUri}/testconf`;
const exerciseConfApiUri = `${apiUri}/exerciseconf`;
const exerciseTemplateApiUri = `${apiUri}/exercise-template`;
const testTemplateApiUri = `${apiUri}/test-template`;
const questionSetApiUri = `${apiUri}/question-set`;
const testImageApiUri = `${apiUri}/testimage`;
const testResultParamsApiUri = `${apiUri}/testresultparams`;
const testResultSApiUri = `${apiUri}/testresults`;
const trueScoreResultApiUri = `${apiUri}/truescoreresult`;
const exerciseResultResultApiUri = `${apiUri}/exerciseresult`;
const invoiceConfigApiUri = `${apiUri}/invoiceconf`;
const clinicConfDetailsApiUri = `${apiUri}/clinicconfdetails`;

export const getTestConf = (testConfId: number, source: CancelTokenSource): Promise<UpsertTestConfDto> => {
  return axios
  .get(`${testConfApiUri}/${testConfId}/details`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getClinicConfigs = (clinicCode: string, source: CancelTokenSource): Promise<ClinicConfDetailsDto> => {
  return axios
  .get(`${clinicConfDetailsApiUri}/${clinicCode}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestConfs = (source: CancelTokenSource): Promise<Array<TestConfDto>> => {
  return axios
  .get(testConfApiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestConfsForPatientTesting = (
  clinicId: number,
  patientTestingId: number | undefined,
  source: CancelTokenSource): Promise<Array<TestConfDto>> => {

  let params: any = {
    clinicId: clinicId,
  };

  if (patientTestingId) {
    params['patientTestingId'] = patientTestingId;
  }

  return axios
  .get(`${testConfApiUri}/clinic`, {
    params: params,
    cancelToken: source.token,
  })
  .then(response => response.data);
};

export const getExerciseConfs = (source: CancelTokenSource): Promise<Array<ExerciseConfDto>> => {
  return axios
  .get(exerciseConfApiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseConfsForPatientExercise = (
  clinicId: number,
  patientExercisingId: number | undefined,
  source: CancelTokenSource): Promise<Array<ExerciseConfDto>> => {

  let params: any = {
    clinicId: clinicId,
  };

  if (patientExercisingId) {
    params['patientExercisingId'] = patientExercisingId;
  }

  return axios
  .get(`${exerciseConfApiUri}/clinic`, {
    params: params,
    cancelToken: source.token,
  })
  .then(response => response.data);
};

export const getAllExerciseTemplates = (source: CancelTokenSource): Promise<ExerciseTemplateDto[]> => {
  return axios
  .get(exerciseTemplateApiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAllTestTemplates = (source: CancelTokenSource): Promise<TestTemplateDto[]> => {
  return axios
  .get(testTemplateApiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestTemplate = (testTemplateId: number, source: CancelTokenSource): Promise<UpsertTestTemplateDto> => {
  return axios
  .get(`${testTemplateApiUri}/${testTemplateId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestInTemplates = (testConfId: number, source: CancelTokenSource): Promise<TestTemplateDto[]> => {
  return axios
  .get(`${testTemplateApiUri}/test-in-templates/${testConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAllQuestionSets = (source: CancelTokenSource): Promise<Array<QuestionSetDto>> => {
  return axios
  .get(`${questionSetApiUri}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchQuestionSet = (
  request: Partial<TeresaConfQuestionSetSearchRequest>,
  source: CancelTokenSource): Promise<QuestionSetDto[]> => {

  return axios
  .post(`${questionSetApiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAllQuestionSetsForPatientTesting = (
  patientTestingId: number,
  source: CancelTokenSource): Promise<QuestionSetsPreview> => {

  return axios
  .get(`${questionSetApiUri}/${patientTestingId}/patient-testing`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getQuestionAnswerPairsForQuestionSet = (request: Partial<UpsertQuestionSetRequest>, source: CancelTokenSource)
  : Promise<UpsertQuestionSetDto> => {
  return axios
  .post(`${questionSetApiUri}/question-answer-pairs`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const getQuestionAnswerPairsForPatientExercising = (
  request: Partial<UpsertQuestionSetRequest>,
  source: CancelTokenSource)
  : Promise<QuestionSetChartDto[]> => {

  return axios
  .post(`${questionSetApiUri}/patient-exercising/question-answer-pairs`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestConfImagesForDisplay = (
  testConfId: number,
  source: CancelTokenSource): Promise<DisplayTestImageDto[]> => {

  return axios
  .get(`${testImageApiUri}/${testConfId}/test-conf-images`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAvailableTestResultParams = (source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${testResultParamsApiUri}/test/available`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseResultParams = (active: boolean | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${testResultParamsApiUri}/exercise`, { params: { active }, cancelToken: source.token })
  .then(response => response.data);
};

export const getAvailableExerciseResultParams = (source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${testResultParamsApiUri}/exercise/available`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestResults = (id: number, source: CancelTokenSource): Promise<TestResultDto[]> => {
  return axios
  .get(`${testResultSApiUri}/${id}/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const calculateFormulaTest = (formulaTest: Partial<FormulaTestDto>)
  : Promise<number> => {
  return axios.post(`${trueScoreResultApiUri}/calculate/formula`, formulaTest)
  .then(response => response.data);
};

export const calculateScoringTest = (scoringTestDto: Partial<ScoringTestDto>, source: CancelTokenSource)
  : Promise<ScoringTestResponse> => {
  return axios.post(`${trueScoreResultApiUri}/calculate`, scoringTestDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseResults = (id: number, source: CancelTokenSource): Promise<ExerciseResultDto[]> => {
  return axios
  .get(`${exerciseResultResultApiUri}/${id}/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseTemplate = (
  exerciseTemplateId: number,
  source: CancelTokenSource,
): Promise<UpsertExerciseTemplateDto> => {
  return axios
  .get(`${exerciseTemplateApiUri}/${exerciseTemplateId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseInTemplates = (
  exerciseConfId: number,
  source: CancelTokenSource,
): Promise<ExerciseTemplateDto[]> => {
  return axios
  .get(`${exerciseTemplateApiUri}/exercise-in-templates/${exerciseConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getInvoiceConfigs = (source: CancelTokenSource): Promise<InvoiceConfigDto[]> => {
  return axios
  .get(invoiceConfigApiUri, { cancelToken: source.token })
  .then(response => response.data);
};