import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import FinalFormColorPicker from 'components/final-form/ColorPicker';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import ImageDropZone from 'components/final-form/ImageDropZone';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import TopRowEntityColorPicker from 'components/final-form/TopRowEntityColorPicker';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import MandateBankTable from 'routes/mandate/MandateBankTable';
import { Grid } from 'semantic-ui-react';
import { addMandate, editMandate, getMandate } from 'service/mandateServices';
import styled from 'styled-components';
import { UpsertMandateBankDto, UpsertMandateDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { required } from 'util/validatorUtils';

const InnerTableColumn = styled(Grid.Column)`
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    &.ui.doubling.grid>.row>.column, 
    &.ui.grid>.doubling.row>.column {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
`

const UpsertMandateForm = () => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const mandateId: number | undefined = state?.id ? Number(state.id) : undefined;
  const mandateBank: UpsertMandateBankDto | undefined = state?.mandateBank ? state.mandateBank : undefined;

  const [mandate, setMandate] =
    React.useState<Partial<UpsertMandateDto> | undefined>({});
  const [formDataLoaded, setFormDataLoaded] =
    React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] =
    React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] =
    useState<string[]>([]);


  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {

    const onFinally = () => setFormDataLoaded(true);

    setFormDataLoaded(false);

    let foundMandate = { ...mandate };

    try {
      if (mandateId) {
        foundMandate = await getMandate(mandateId, cancelTokenSource);
        if (mandateBank) {
          const currentMandateBanks =
            foundMandate.mandateBanks != undefined ? [...foundMandate.mandateBanks] : [];
          foundMandate.mandateBanks = [...currentMandateBanks, mandateBank];
        }
        setMandate(foundMandate);
      }
    } catch (e) {

    } finally {
      onFinally();
    }

  };

  const handleError = useCallback((error: any) => {
    if (error) {
      let errMsg = undefined;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.mandateNotFound,
        errorUtils.invalidIbanFormat
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (violation.details) {
            errMsg = violation.details;
          } else if (knownErrors.includes(violation.errorCode)) {
            errMsg = (t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errMsg) {
        if (knownErrors.includes(errorCode)) {
          errMsg = (t(`error.${errorCode}`));
        } else {
          errMsg = (t('error.general'));
        }
      }

      setErrorMessage(errMsg);
    }
  }, []);


  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const imageSizeExceeded = useCallback((values: Partial<UpsertMandateDto>) => {

    if (values.image && values.image.size >= 1048576) {

      setErrorMessages([t(`error.${errorUtils.fileSizeExceeded}`)]);

      return true;
    }

    return false;
  }, []);

  const handleSubmit = useCallback(async (values: UpsertMandateDto) => {

    let saveValues: UpsertMandateDto = {
      ...values
    };

    const imageSizeExceed = imageSizeExceeded(values);

    if (imageSizeExceed) {
      return;
    }

    const onSave = () => {
      mandateId
        ? setSuccessMsg(t('mandate.onUpdate'))
        : setSuccessMsg(t('mandate.onAdd'));
      setTimeout(() => goToPreviousPage(), 1200);
    };

    if (mandateId) {
      await editMandate(mandateId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addMandate(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  }, []);

  const goToPreviousPage = useCallback(() => {
    let clinicSearch = '/mandates';

    push(clinicSearch);

  }, []);

  const getFineInvalidTypeError = (errorCode: string) => {
    if (errorCode === 'file-invalid-type') {
      setErrorMessage(t(`error.${errorUtils.fileTypeInvalid}`));
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertMandateDto) => handleSubmit(values)}
        initialValues={mandate}
        mutators={{ ...arrayMutators }}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertMandateDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting, form, values }:
                               FormRenderProps<UpsertMandateDto>): React.ReactNode => {

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('mandate.title')}</div>
            </Grid.Column>
          </Grid.Row>

          {
            errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={12}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Row>
                  <InnerTableColumn width={6}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('mandate.name')}</DataLabel>
                          <Field
                            fluid
                            name={'name'}
                            component={Input}
                            validate={required}
                            autoFocus
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('mandate.street')}</DataLabel>
                          <Field
                            fluid
                            name={'street'}
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('mandate.zip')}</DataLabel>
                          <Field
                            fluid
                            name={'zip'}
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('mandate.city')}</DataLabel>
                          <Field
                            fluid
                            name={'city'}
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <TopRowEntityColorPicker>
                            <div style={{ display: "inline-flex" }}>
                              <DataLabel style={{ paddingRight: '1rem' }}>
                                {t('mandate.color')}
                              </DataLabel>
                              <Field
                                id='color'
                                name='color'
                                component={FinalFormColorPicker}
                              />
                            </div>
                          </TopRowEntityColorPicker>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <div style={{ display: "inline-flex" }}>
                            <DataLabel style={{ paddingRight: '1rem' }}>{t('mandate.active')}</DataLabel>
                            <Field
                              name='active'
                              component={CheckBox}
                              toggle={true}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </InnerTableColumn>
                  <InnerTableColumn width={6}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('mandate.email')}</DataLabel>
                          <Field
                            fluid
                            name={'email'}
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('mandate.telephone')}</DataLabel>
                          <Field
                            fluid
                            name={'telephone'}
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <Field name='image'
                                 component={ImageDropZone}
                                 imageView={values.image ? undefined : values.imageView}
                                 removeImageView={() => {
                                   form.change('imageView', undefined);
                                   form.change('image', undefined);
                                 }
                                 }
                                 getErrorCode={getFineInvalidTypeError}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </InnerTableColumn>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <MandateBankTable
            existingMandateBanks={mandate ? mandate.mandateBanks : undefined}
          />

          <Grid.Row>
            <Grid.Column width={15}
                         style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={15} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('mandate.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};
export default UpsertMandateForm;