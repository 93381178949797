import DataLabel from 'components/final-form/DataLabel';
import CompositeButton from 'components/final-form/CompositeButton';
import MainContainerComponent from 'components/MainContainerComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import _ from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ActivateAccountForm from 'routes/ActivateAccountForm';
import { Grid, Input, InputOnChangeData } from 'semantic-ui-react';
import { validateRegistrationKey } from 'service/accountService';
import styled from 'styled-components';
import { UpsertAccountDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { isKeyCheck } from 'util/keyUtils';

const GridColumn = styled(Grid.Column)`
  padding-right: 0 !important;
`

const ContainerDiv = styled.div`

  .container-div {
    justify-content: center;
    background-color: white;
    border: 1px solid var(--very-very-light-gray);
    padding: 50px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   }

  .header-div {
    text-align: center;
   }

  .auth-form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    grid-column-gap: 1rem;
    grid-row-gap: 2rem;

    margin-top: 2.5rem;

    label {
      align-self: center;
      width: 20rem;
    }
    
    .btn-span {
      display: inline-flex;
      margin-left: 6rem;
      padding-right: 7rem;
    }

    .error-message {
      margin-bottom: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      position: relative;

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      &.text {
        margin-top: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .auth-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }
      
      .btn-span {
        margin-left: unset;
      }

      .action-button {
        display: block;
        width: 100%;
      }
    }
  }
`;

export enum AccountActivationViews {
  VALIDATE_REGISTRATION_KEY = 'VALIDATE_REGISTRATION_KEY',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL'
}

const ActivationKeyView = () => {

  const { t } = useTranslation('login');
  const history = useHistory();

  const [activeView, setActiveView] =
    useState<AccountActivationViews>(AccountActivationViews.VALIDATE_REGISTRATION_KEY);

  const [activationKey, setActivationKey] = useState('');
  const [account, setAccount] = React.useState<Partial<UpsertAccountDto> | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [fieldError, setFieldError] = useState<boolean>(false);

  const goToLogin = () => {
    history.push('/login');
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.invalidRegistrationKey];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }

        });

      } else {

        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };


  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }

  };

  const setInputKey = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setActivationKey(data.value);
  };

  const onEnterKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      activationKeyValidation();
    }
  };

  const activationKeyValidation = async () => {

    if (_.isEmpty(activationKey)) {
      setFieldError(true);
      return;
    }

    try {
      const response = await validateRegistrationKey(activationKey);
      setAccount(response);
      setActiveView(AccountActivationViews.ACTIVATE_ACCOUNT);
    } catch (e: any) {
      handleError(e.response.data);
    }
  };

  const renderValidateRegKeyContent = (): React.ReactNode => {
    return <MainContainerComponent>
      <ContainerDiv>
        <div className={'container-div'}>
          <div className={'header-div'}>
            <div className='title-h1'>{t('register.title')}</div>
          </div>
          <TsaGrid>
            <Grid.Row>
              <GridColumn width={16}>
                <TsaGrid>
                  {
                    errorMessages.length > 0 &&
                    <div className='error-message'>
                      <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                        {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                      </StyledErrorMessage>
                    </div>

                  }

                  <Grid.Row>
                    <Grid.Column width={16}>
                      <DataLabel>{t('register.activationKey')}</DataLabel>
                      <Input
                        placeholder=''
                        value={activationKey}
                        onChange={setInputKey}
                        error={fieldError}
                        onKeyDown={(e: any) => onEnterKeyDown(e)}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </TsaGrid>
              </GridColumn>
            </Grid.Row>

            <Grid.Row>
              <GridColumn width={8} textAlign={'right'}>
                <CompositeButton
                  type='button'
                  className='action-button'
                  style={{ marginRight: '2rem' }}
                  primary
                  onClick={activationKeyValidation}
                  disabled={activationKey === undefined || activationKey === ''}
                >
                  {t('register.validate')}
                </CompositeButton>
              </GridColumn>
              <GridColumn width={8} textAlign={'left'}>
                <CompositeButton
                  type='button'
                  className='action-button'
                  style={{ whiteSpace: 'nowrap', marginRight: '5rem' }}
                  secondary
                  onClick={goToLogin}
                >
                  {t('action.backToLogin')}
                </CompositeButton>
              </GridColumn>
            </Grid.Row>
          </TsaGrid>
        </div>
      </ContainerDiv>
    </MainContainerComponent>;
  };

  const renderActiveView = () => {

    switch (activeView) {
      case AccountActivationViews.VALIDATE_REGISTRATION_KEY: {
        return renderValidateRegKeyContent();
      }
      case AccountActivationViews.ACTIVATE_ACCOUNT: {
        return <ActivateAccountForm
          accountData={account}
        />;
      }
      default: {
        return renderValidateRegKeyContent();
      }
    }
  };

  return <>
    {renderActiveView()}
  </>;
};

export default ActivationKeyView;
